
export const VelcordMediaLogo = ({ color = "#C79B75" }) => (
    <svg
        fill={color}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="25 139.34 1176.17 135.34"
        width="310px"
        height="135.34px"
    >
        <g>
            <path d="M1067.33,482.38H1151L905.22,90.68l-50.17-79.56l-49.67,79.84l-110.71,178.6L585.52,96.53l-50.25-79.67
                l-49.69,79.95L330.34,347.14L176.52,103.52c-21.01-33.27-49.98-60.61-84.44-79.59c-0.54-0.3-1.08-0.59-1.62-0.88l-0.03-0.02
                c-0.45-0.25-0.9-0.49-1.36-0.73l-1.7-0.89l-1.66-0.86l-1.62-0.82l-1.49-0.74l-1.02-0.5l-0.65-0.32l-0.75-0.36l-0.92-0.44
                l-0.74-0.35l-0.84-0.39l-1.66-0.76l-0.7-0.31l-0.97-0.44l-0.85-0.38l-0.66-0.29l-1.09-0.47l-0.63-0.27l-1-0.42l-0.6-0.25
                l-1.16-0.48l-0.5-0.21l-1-0.41l-0.72-0.29l-1.07-0.42l-0.5-0.2l-1.16-0.45l-0.56-0.22l-1.1-0.42l-0.59-0.22l-1.04-0.39l-0.7-0.26
                l-0.87-0.31L57.7,8.46l-0.86-0.3l-0.83-0.29l-0.92-0.32L54.36,7.3l-0.96-0.32l-0.78-0.26l-0.92-0.3l-0.78-0.25l-1.02-0.32
                l-0.55-0.17l-1.29-0.4l-0.39-0.12l-1.29-0.39l-0.45-0.13l-1.26-0.37l-0.48-0.14l-1.25-0.36l-0.51-0.14l-1.19-0.33l-0.61-0.17
                l-1.13-0.3l-0.55-0.14l-1.3-0.34l-0.37-0.09l-1.23-0.31l-0.72-0.18l-1.07-0.26l-0.6-0.14l-1.2-0.28l-0.44-0.1l-1.4-0.32L30.4,0.58
                l-1.52-0.33L28.63,0.2L27.71,0l252.87,400.73l50.16,79.6l49.68-79.91L535.8,150.27l117.48,186.06l-92.47,146.05h83.05l52.35-79.42
                l47.99,79.42h83.64L736.65,335.9l118.92-191.78L1067.33,482.38L1067.33,482.38z"/>
        </g>
    </svg>
)
