import * as React from 'react'
import { Container } from '@mui/material'
import Typography from '../components/Typography'
import FacebookIcon from '@mui/icons-material/Facebook'
import InstagramIcon from '@mui/icons-material/Instagram'
import CallIcon from '@mui/icons-material/Call'
import EmailIcon from '@mui/icons-material/Email'
import SvgIcon from '@mui/material/SvgIcon'
import Link from '@mui/material/Link'
import { TikTokIcon } from '../components/TikTokIcon'
import { contactData } from 'src/data'
import { secondaryColor } from '../theme'


interface ContactState {
    component: React.JSX.Element
    selected?: string,
    colors: {
      call: string
      email: string
      facebook: string
      instagram: string
      tiktok: string
    }
}

const defaultColors = {
    call: 'white',
    email: 'white',
    facebook: 'white',
    instagram: 'white',
    tiktok: 'white',
}

export default function HeaderContact({}) {
    const [contactDetail, setValue] = React.useState<ContactState>({
        component: <Typography align="center">&nbsp;</Typography>,
        colors: defaultColors
      })
    
      const handleChange = (eventSelected: string) => {
        //const selected = event.currentTarget.getAttribute("value") ?? undefined
        const colors = { ...defaultColors }
        let component = <Typography align="center">&nbsp;</Typography>
        let selected: string | undefined = undefined
        const sameSelect = contactDetail.selected === eventSelected 
    //'secondary.light'
      if (!sameSelect && eventSelected === 'call') {
          selected = eventSelected
          component = <Typography align="center"><Link color="inherit" href={`tel:${contactData.phoneNumber}`} rel="sponsored" >{contactData.phoneNumber}</Link></Typography>
          colors.call = secondaryColor
        } else if (!sameSelect && eventSelected === 'email') {
          selected = eventSelected
          component = <Typography align="center"><Link color="inherit" href={`mailto:${contactData.emailAddress}`} rel="sponsored" >{contactData.emailAddress}</Link></Typography>
          colors.email = secondaryColor
        }
        
        setValue({
          component,
          selected,
          colors,
        })
      }

      return (
        <Container maxWidth="md" component="main">
        <Typography align="center">
          <Link color="inherit" href={contactData.facebookUrl} rel="sponsored" title="Flaticon">
            <FacebookIcon onClick={() => handleChange('facebook')} htmlColor={contactDetail.colors.facebook} fontSize='large' sx={{ mr: 2 }}/>
          </Link>
          <Link color="inherit" href={contactData.instagrammUrl} rel="sponsored" title="Flaticon">
            <InstagramIcon onClick={() => handleChange('instagram')} htmlColor={contactDetail.colors.instagram} fontSize='large' sx={{ mr: 2 }}/>
          </Link>
          <CallIcon onClick={() => handleChange('call')} htmlColor={contactDetail.colors.call} fontSize='large' sx={{ mr: 2 }}/>
          <EmailIcon onClick={() => handleChange('email')} htmlColor={contactDetail.colors.email} fontSize='large' sx={{ mr: 2 }}/>
          <Link color="inherit" href={contactData.tiktokUrl} rel="sponsored" title="Flaticon">
            <SvgIcon fontSize='large' component={TikTokIcon} htmlColor={contactDetail.colors.tiktok}/>
          </Link>
        </Typography>
        {contactDetail.component}
        </Container>
    )
}
