import * as React from 'react'
import Typography from '../components/Typography'
import ProductHeroLayout from './ProductHeroLayout'
import { VelcordMediaLogo } from '../components/VelcordMediaLogo'
import { VelcordMediaLogoName } from '../components/VelcordMediaLogoName'
import HeaderContact from './HeaderContact'

export default function AppHeader({backgroundImage}: {backgroundImage: string}) {

  return (
    <ProductHeroLayout
      sxBackground={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundColor: '#7fc7d9',
        backgroundPosition: 'center',
      }}
    >
      <img
        style={{ display: 'none' }}
        src={backgroundImage}
        alt="increase priority"
      />
      <Typography color="inherit" align="center"  marked="center" sx={{ mb: 2 }}>
        <VelcordMediaLogo />
      </Typography>
      <Typography color="inherit" align="center" marked="center" sx={{ mb: 10 }}>
        <VelcordMediaLogoName />
      </Typography>

      <HeaderContact />

    </ProductHeroLayout>
  )
}
