import * as React from 'react'
import { Navigate, useParams } from 'react-router-dom'
import { getGalleryData, references } from '../../data'
import DetailGallery from '../views/DetailGallery'
import Line from '../components/Line'
import Typography from '../components/Typography'

export default function GalleryRoute() {
  const { id } = useParams()
  const library = getGalleryData(id!)

  if (!library) return (<Navigate to="/" />)

  const title = references.images.find(image => image.galleryId === id)?.title ?? ''

  return (
    <React.Fragment>
      <Typography align="center" variant="h6" sx={{p:1}}>{title}</Typography>
      <Line/>
      <DetailGallery library={library} />
    </React.Fragment>
  )
}
