import * as React from 'react'
import Box from '@mui/material/Box'
import CssBaseline from '@mui/material/CssBaseline'
import Typography from '../components/Typography';
import GlobalStyles from '@mui/material/GlobalStyles'
import Container from '@mui/material/Container'
import Tab from '@mui/material/Tab'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import PriceTabPanel from './PriceTabPanel'
import { ServicesTier } from 'src/data';

export default function Pricing({tiers}: {tiers: ServicesTier[]}) {
  const [value, setValue] = React.useState('0')
  const handleChange = (event: React.SyntheticEvent<Element, Event>, newValue: string) => {
    setValue(newValue)
  }
  return (
    <Container  sx={{ mt: 8, mb: 4 }}>
      <Typography variant="h4" marked="center" align="center" component="h1">
        Csomagok
      </Typography>
      
      <Container disableGutters maxWidth="md" component="main" sx={{ pt: 8, pb: 6 }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChange} aria-label="" centered TabIndicatorProps={{style: {backgroundColor: "secondary"}}}>
              <Tab label={tiers[0].title} value="0" />
              <Tab label={tiers[1].title} value="1" />
            </TabList>
          </Box>
          <TabPanel value="0"><PriceTabPanel { ...{title: tiers[0].title, tiers: tiers[0].tiers}} /></TabPanel>
          <TabPanel value="1"><PriceTabPanel { ...{title: tiers[1].title, tiers: tiers[1].tiers}} /></TabPanel>
        </TabContext>
      </Container>
    </Container>
  )
}
