import * as React from 'react'
import Typography from '../components/Typography'

function Copyright() {
  return (
    <React.Fragment>
      {'©Velcord Media '}
      {new Date().getFullYear()}
    </React.Fragment>
  )
}

export default function AppFooter() {
  return (
    <React.Fragment>
      <Typography sx={{ p:1, textAlign: 'center', fontWeight: 300, }} >
        <Copyright />
      </Typography>
    </React.Fragment>
  )
}
