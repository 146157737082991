import { Image } from "react-grid-gallery";
import { useHref } from "react-router-dom";

export interface CustomImage extends Image {
  lightBox: {
    src: string
    srcSet: {
      src: string
      width: number
      height: number
    }[]
  }
}

export interface ImageLibrary {
  images: CustomImage[]
}

export interface ImageLibraries { [key: string]: ImageLibrary }

export interface ServicesTier {
  title: string
  tiers: {
    title: string
    subheader?: string
    price: string
    description: {
      short: string
      long: string
    }[]
    buttonText: string
    buttonVariant: string
  }[]
}

export const contactData = {
  phoneNumber: '+36 30 946 4273',
  emailAddress: 'info@velcord.hu',
  instagrammUrl: 'https://www.instagram.com/velcordmedia/',
  facebookUrl: 'https://www.facebook.com/Velcordmedia',
  tiktokUrl: 'https://www.tiktok.com/@velcordmedia',

}

export const tiers: ServicesTier[] = [
  {
    title: 'Videós csomagok',
    tiers: [
      {
        title: 'Klip',
        subheader: 'legnépszerűbb',
        price: '240 000',
        description: [
          {short: '12 órás rendelkezésre állás', long: '12 órás rendelkezésre állás'},
          {short: '2 kamera, 1 operatőr', long: 'Két kamerával, egy operatőrrel készített felvételek'},
          {short: 'Drón felvételek', long: ''},
          {short: '5 vagy 10 perces film', long: 'Esküvői klip film (5-10 perces szerkesztett, rövidített esküvői film)'},
          {short: '-', long: '-'},
          {short: '-', long: '-'},
          {short: 'Online tárolás', long: 'Az elkészült filmek online tárolása'},
          {short: 'Lézergravírozott pendrive', long: '1 db egyedi designnal ellátott, lézergravírozott pendrive kézműves díszdobozban'},
          {short: '-', long: '-'},
          {short: '-', long: '-'},
          {short: '-', long: '-'},
        ],
        buttonText: '',
        buttonVariant: '',
      },
      {
        title: 'Alap',
        
        price: '300 000',
        description: [
          {short: '12 órás rendelkezésre állás', long: '12 órás rendelkezésre állás'},
          {short: '2 kamera, 2 operatőr', long: 'Két kamerával, két operatőrrel készített felvételek'},
          {short: 'Drón felvételek', long: ''},
          {short: '30 perces highlight film ', long: 'Esküvői highlight film (30 perces szerkesztett, rövidített esküvői film)'},
          {short: '3 perces összefoglaló film', long: 'Zenés összefoglaló rövidfilm a teljes nap eseményeiről (3-4perc)'},
          {short: '-', long: '-'},
          {short: 'Online tárolás', long: 'Az elkészült filmek online tárolása'},
          {short: 'Lézergravírozott pendrive', long: '1 db egyedi designnal ellátott, lézergravírozott pendrive kézműves díszdobozban'},
          {short: 'Vágatlan videók átadása ', long: ''},
          {short: '-', long: '-'},
          {short: '-', long: '-'},
        ],
        buttonText: '',
        buttonVariant: '',
      },
      {
        title: 'Exkluzív',
        price: '420 000',
        description: [
          {short: '14 órás rendelkezésre állás', long: '14 órás rendelkezésre állás'},
          {short: '3 kamera, 2 operatőr', long: 'Két kamerával, két operatőrrel készített felvételek'},
          {short: 'Drón felvételek', long: ''},
          {short: '30 perces highlight film', long: 'Esküvői highlight film (30 perces szerkesztett, rövidített esküvői film)'},
          {short: '3 perces összefoglaló film', long: 'Zenés összefoglaló rövidfilm a teljes nap eseményeiről (3-4perc)'},
          {short: 'Szertartás teljes hosszában', long: ''},
          {short: 'Online tárolás', long: 'Az elkészült filmek online tárolása'},
          {short: 'Lézergravírozott pendrive', long: '1 db egyedi designnal ellátott, lézergravírozott pendrive kézműves díszdobozban'},
          {short: 'Vágatlan videók átadása ', long: ''},
          {short: 'Instax fotógép', long: 'Instax fotógép bérlése az esti mulatság ideje alatt (további filmek kérhetőek)'},
          {short: 'Kreatív videósarok', long: 'Kreatív videósarok szerkesztett felvételei (8-10 perc)'},
        ],
        buttonText: '',
        buttonVariant: '',
      },
    ]
  },
  {
    title: 'Fotós csomagok',
    tiers: [
      {
        title: 'Szertartás',
        price: '170 000',
        description: [
          {short: '5 órás rendelkezésre állás', long: '5 órás rendelkezésre állás'},
          {short: 'Szertartások fotózása', long: 'Szertartás(ok) fotózása'},
          {short: 'Kreatív fotózás', long: 'Kreatív fotózás az esküvővel azonos napon'},
          {short: 'Csoportképek', long: 'Kis csoportos fotók készítése és teljesz násznépi csoportkép'},
          {short: '-', long: '-'},
          {short: '-', long: '-'},
          {short: '200 db kép', long: 'Legalább 200 db kidolgozott fotó átadása online formában és díszdobozos pendriveon'},
          {short: 'Online tárolás', long: 'Az elkészült képek online tárolása'},
          {short: '-', long: '-'},
          {short: '-', long: '-'},
          {short: '-', long: '-'},
          {short: '-', long: '-'},
        ],
        buttonText: '',
        buttonVariant: '',
      },
      {
        title: 'Alap',
        subheader: 'legnépszerűbb',
        price: '275 000',
        description: [
          {short: '12 órás rendelkezésre állás', long: '12 órás rendelkezésre állás'},
          {short: 'Szertartások fotózása', long: 'Szertartás(ok) fotózása'},
          {short: 'Kreatív fotózás', long: 'Kreatív fotózás az esküvővel azonos vagy eltérő napon'},
          {short: 'Csoportképek', long: 'Kis csoportos fotók készítése és teljesz násznépi csoportkép'},
          {short: 'Készülődés fotózása', long: ''},
          {short: 'Party fotók', long: 'fotózás a vacsora helyszínén a menyecske/új asszony táncig'},
          {short: '500 db kép', long: 'Legalább 500 db kidolgozott fotó átadása online formában és díszdobozos pendriveon'},
          {short: 'Online tárolás', long: 'Az elkészült képek online tárolása'},
          {short: 'Lézergravírozott pendrive', long: '1 db egyedi designnal ellátott, lézergravírozott pendrive kézműves díszdobozban'},
          {short: '30x40 cm vászonkép', long: '1 db 30x40cm méretű vászonkép, választott fotóból'},
          {short: '-', long: '-'},
          {short: '-', long: '-'},
        ],
        buttonText: '',
        buttonVariant: '',
      },
      {
        title: 'Exkluzív',
        price: '330 000',
        description: [
          {short: '14 órás rendelkezésre állás', long: '14 órás rendelkezésre állás'},
          {short: 'Szertartások fotózása', long: 'Szertartás(ok) fotózása'},
          {short: 'Kreatív fotózás', long: 'Kreatív fotózás az esküvővel azonos vagy eltérő napon'},
          {short: 'Csoportképek', long: 'Kis csoportos fotók készítése és teljesz násznépi csoportkép'},
          {short: 'Készülődés fotózása', long: ''},
          {short: 'Party fotók', long: 'fotózás a vacsora helyszínén a menyecske/új asszony táncig'},
          {short: '700 db kép', long: 'Legalább 700 db kidolgozott fotó átadása online formában és díszdobozos pendriveon'},
          {short: 'Online tárolás', long: 'Az elkészült képek online tárolása'},
          {short: 'Lézergravírozott pendrive', long: '1 db egyedi designnal ellátott, lézergravírozott pendrive kézműves díszdobozban'},
          {short: '30x40 cm vászonkép', long: '1 db 30x40cm méretű vászonkép, választott fotóból'},
          {short: 'Jegyesfotózás', long: 'Jegyesfotózás'},
          {short: 'Best of fotók slideshow', long: 'Best of fotók slideshow'},
        ],
        buttonText: '',
        buttonVariant: '',
      },
    ]
  }
];

export const references = {
  //https://velcord.hu/refphoto/IMG_0304.jpg' />
  coverImage: '/gwO3DbkX.jpeg',
  images: [
    {
      type: 'photoGallery',
      galleryId: 'wedding',
      url: '/references/wedding/I22A2036_480.jpg',
      title: 'Esküvői fotó',
      width: '24%',
    },
    {
      type: 'photoGallery',
      galleryId: 'jegyesfoto',
      url: '/references/jegyesfoto/DSC02699_480.jpg',
      title: 'Jegyesfotó',
      width: '20%',
    },
    {
      type: 'photoGallery',
      galleryId: 'kismama',
      url: '/references/kismama/I22A0306_480.jpg',
      title: 'Kismama fotó',
      width: '28%',
    },
    {
      type: 'photoGallery',
      galleryId: 'portre',
      url: '/I22A0508.jpg',
      title: 'Portré',
      width: '28%',
    },
    {
      type: 'youtubeVideo',
      videoId: 'hS_nSGrVuQY',
      title: 'Ani & Gergő',
      width: '40%',
    },
    {
      type: 'youtubeVideo',
      videoId: 'NjOqdwqXzY4',
      title: 'Réka & Gábor',
      width: '20%',
},

{
      type: 'youtubeVideo',
      videoId: 'XacIGgKDYQM',
      title: 'Alexa & Johnny - Kőröshegyi Levendulás',
      width: '40%',
},
{
      type: 'youtubeVideo',
      videoId: 'qfDIXD-r5MU',
      title: 'Niki & Sanyi',
      width: '20%',
},
{
      type: 'youtubeVideo',
      videoId: 'rUQp2ZhEiCI',
      title: 'Ági & Gabi',
      width: '40%',
},
{
      type: 'youtubeVideo',
      videoId: 'prtnv2aSxS4',
      title: 'Reni & Szilveszter',
      width: '40%',
},
{
      type: 'youtubeVideo',
      videoId: 'WrbowQ6KzrU',
      title: 'Mariann & Zoli',
      width: '30%',
},
{
      type: 'youtubeVideo',
      videoId: '5Er3TDGggfY',
      title: 'Fanni & Dániel',
      width: '35%',
},
{
      type: 'youtubeVideo',
      videoId: 'GGl-Kh9VrLI',
      title: 'Ena & Gabi',
      width: '35%',
},
{
      type: 'youtubeVideo',
      videoId: 'AA1SfX036JY',
      title: 'Dorottya & Dávid',
      width: '20%',
},
{
      type: 'youtubeVideo',
      videoId: '8pJpmL93JOY',
      title: 'Betti & Jani',
      width: '40%',
},
{
      type: 'youtubeVideo',
      videoId: 't-dJfpDhKoY',
      title: 'Csilla & Joci',
      width: '40%',
},
  {
    type: 'youtubeVideo',
    videoId: '6do6yo0r6Io',
    title: 'Viki & Viktor',
    width: '50%',
  },
  {
    type: 'youtubeVideo',
    videoId: 'AA1SfX036JY',
    title: 'Kreatív képek',
    width: '50%',
  },
]}

const imageRefernceLibrary: ImageLibraries = 
{
  "kismama": {
    "images": [
      {
        "src": "/references/kismama/I22A0215_480.jpg",
        "lightBox": {
          "src": "/references/kismama/I22A0215_1024.jpg",
          "srcSet": [
            {
              "src": "/references/kismama/I22A0215_480.jpg",
              "width": 320,
              "height": 480
            },
            {
              "src": "/references/kismama/I22A0215_600.jpg",
              "width": 400,
              "height": 600
            },
            {
              "src": "/references/kismama/I22A0215_800.jpg",
              "width": 533,
              "height": 800
            },
            {
              "src": "/references/kismama/I22A0215_1024.jpg",
              "width": 683,
              "height": 1024
            }
          ]
        },
        "width": 683,
        "height": 1024,
        "caption": ""
      },
      {
        "src": "/references/kismama/I22A0233_480.jpg",
        "lightBox": {
          "src": "/references/kismama/I22A0233_1024.jpg",
          "srcSet": [
            {
              "src": "/references/kismama/I22A0233_480.jpg",
              "width": 320,
              "height": 480
            },
            {
              "src": "/references/kismama/I22A0233_600.jpg",
              "width": 400,
              "height": 600
            },
            {
              "src": "/references/kismama/I22A0233_800.jpg",
              "width": 533,
              "height": 800
            },
            {
              "src": "/references/kismama/I22A0233_1024.jpg",
              "width": 683,
              "height": 1024
            }
          ]
        },
        "width": 683,
        "height": 1024,
        "caption": ""
      },
      {
        "src": "/references/kismama/I22A0235_480.jpg",
        "lightBox": {
          "src": "/references/kismama/I22A0235_1024.jpg",
          "srcSet": [
            {
              "src": "/references/kismama/I22A0235_480.jpg",
              "width": 480,
              "height": 320
            },
            {
              "src": "/references/kismama/I22A0235_600.jpg",
              "width": 600,
              "height": 400
            },
            {
              "src": "/references/kismama/I22A0235_800.jpg",
              "width": 800,
              "height": 533
            },
            {
              "src": "/references/kismama/I22A0235_1024.jpg",
              "width": 1024,
              "height": 683
            }
          ]
        },
        "width": 1024,
        "height": 683,
        "caption": ""
      },
      {
        "src": "/references/kismama/I22A0276_480.jpg",
        "lightBox": {
          "src": "/references/kismama/I22A0276_1024.jpg",
          "srcSet": [
            {
              "src": "/references/kismama/I22A0276_480.jpg",
              "width": 320,
              "height": 480
            },
            {
              "src": "/references/kismama/I22A0276_600.jpg",
              "width": 400,
              "height": 600
            },
            {
              "src": "/references/kismama/I22A0276_800.jpg",
              "width": 533,
              "height": 800
            },
            {
              "src": "/references/kismama/I22A0276_1024.jpg",
              "width": 683,
              "height": 1024
            }
          ]
        },
        "width": 683,
        "height": 1024,
        "caption": ""
      },
      {
        "src": "/references/kismama/I22A0306_480.jpg",
        "lightBox": {
          "src": "/references/kismama/I22A0306_1024.jpg",
          "srcSet": [
            {
              "src": "/references/kismama/I22A0306_480.jpg",
              "width": 480,
              "height": 320
            },
            {
              "src": "/references/kismama/I22A0306_600.jpg",
              "width": 600,
              "height": 400
            },
            {
              "src": "/references/kismama/I22A0306_800.jpg",
              "width": 800,
              "height": 533
            },
            {
              "src": "/references/kismama/I22A0306_1024.jpg",
              "width": 1024,
              "height": 683
            }
          ]
        },
        "width": 1024,
        "height": 683,
        "caption": ""
      },
      {
        "src": "/references/kismama/I22A0322_480.jpg",
        "lightBox": {
          "src": "/references/kismama/I22A0322_1024.jpg",
          "srcSet": [
            {
              "src": "/references/kismama/I22A0322_480.jpg",
              "width": 480,
              "height": 320
            },
            {
              "src": "/references/kismama/I22A0322_600.jpg",
              "width": 600,
              "height": 400
            },
            {
              "src": "/references/kismama/I22A0322_800.jpg",
              "width": 800,
              "height": 533
            },
            {
              "src": "/references/kismama/I22A0322_1024.jpg",
              "width": 1024,
              "height": 683
            }
          ]
        },
        "width": 1024,
        "height": 683,
        "caption": ""
      },
      {
        "src": "/references/kismama/I22A0350_480.jpg",
        "lightBox": {
          "src": "/references/kismama/I22A0350_1024.jpg",
          "srcSet": [
            {
              "src": "/references/kismama/I22A0350_480.jpg",
              "width": 480,
              "height": 330
            },
            {
              "src": "/references/kismama/I22A0350_600.jpg",
              "width": 600,
              "height": 412
            },
            {
              "src": "/references/kismama/I22A0350_800.jpg",
              "width": 800,
              "height": 549
            },
            {
              "src": "/references/kismama/I22A0350_1024.jpg",
              "width": 1024,
              "height": 703
            }
          ]
        },
        "width": 1024,
        "height": 703,
        "caption": ""
      },
      {
        "src": "/references/kismama/I22A0364_480.jpg",
        "lightBox": {
          "src": "/references/kismama/I22A0364_1024.jpg",
          "srcSet": [
            {
              "src": "/references/kismama/I22A0364_480.jpg",
              "width": 480,
              "height": 320
            },
            {
              "src": "/references/kismama/I22A0364_600.jpg",
              "width": 600,
              "height": 400
            },
            {
              "src": "/references/kismama/I22A0364_800.jpg",
              "width": 800,
              "height": 533
            },
            {
              "src": "/references/kismama/I22A0364_1024.jpg",
              "width": 1024,
              "height": 683
            }
          ]
        },
        "width": 1024,
        "height": 683,
        "caption": ""
      },
      {
        "src": "/references/kismama/I22A0385_480.jpg",
        "lightBox": {
          "src": "/references/kismama/I22A0385_1024.jpg",
          "srcSet": [
            {
              "src": "/references/kismama/I22A0385_480.jpg",
              "width": 320,
              "height": 480
            },
            {
              "src": "/references/kismama/I22A0385_600.jpg",
              "width": 400,
              "height": 600
            },
            {
              "src": "/references/kismama/I22A0385_800.jpg",
              "width": 533,
              "height": 800
            },
            {
              "src": "/references/kismama/I22A0385_1024.jpg",
              "width": 683,
              "height": 1024
            }
          ]
        },
        "width": 683,
        "height": 1024,
        "caption": ""
      },
      {
        "src": "/references/kismama/I22A0395_480.jpg",
        "lightBox": {
          "src": "/references/kismama/I22A0395_1024.jpg",
          "srcSet": [
            {
              "src": "/references/kismama/I22A0395_480.jpg",
              "width": 320,
              "height": 480
            },
            {
              "src": "/references/kismama/I22A0395_600.jpg",
              "width": 400,
              "height": 600
            },
            {
              "src": "/references/kismama/I22A0395_800.jpg",
              "width": 533,
              "height": 800
            },
            {
              "src": "/references/kismama/I22A0395_1024.jpg",
              "width": 683,
              "height": 1024
            }
          ]
        },
        "width": 683,
        "height": 1024,
        "caption": ""
      },
      {
        "src": "/references/kismama/I22A0430_480.jpg",
        "lightBox": {
          "src": "/references/kismama/I22A0430_1024.jpg",
          "srcSet": [
            {
              "src": "/references/kismama/I22A0430_480.jpg",
              "width": 480,
              "height": 291
            },
            {
              "src": "/references/kismama/I22A0430_600.jpg",
              "width": 600,
              "height": 364
            },
            {
              "src": "/references/kismama/I22A0430_800.jpg",
              "width": 800,
              "height": 485
            },
            {
              "src": "/references/kismama/I22A0430_1024.jpg",
              "width": 1024,
              "height": 621
            }
          ]
        },
        "width": 1024,
        "height": 621,
        "caption": ""
      },
      {
        "src": "/references/kismama/I22A0512_480.jpg",
        "lightBox": {
          "src": "/references/kismama/I22A0512_1024.jpg",
          "srcSet": [
            {
              "src": "/references/kismama/I22A0512_480.jpg",
              "width": 480,
              "height": 320
            },
            {
              "src": "/references/kismama/I22A0512_600.jpg",
              "width": 600,
              "height": 400
            },
            {
              "src": "/references/kismama/I22A0512_800.jpg",
              "width": 800,
              "height": 533
            },
            {
              "src": "/references/kismama/I22A0512_1024.jpg",
              "width": 1024,
              "height": 683
            }
          ]
        },
        "width": 1024,
        "height": 683,
        "caption": ""
      },
      {
        "src": "/references/kismama/I22A0553_480.jpg",
        "lightBox": {
          "src": "/references/kismama/I22A0553_1024.jpg",
          "srcSet": [
            {
              "src": "/references/kismama/I22A0553_480.jpg",
              "width": 320,
              "height": 480
            },
            {
              "src": "/references/kismama/I22A0553_600.jpg",
              "width": 400,
              "height": 600
            },
            {
              "src": "/references/kismama/I22A0553_800.jpg",
              "width": 533,
              "height": 800
            },
            {
              "src": "/references/kismama/I22A0553_1024.jpg",
              "width": 683,
              "height": 1024
            }
          ]
        },
        "width": 683,
        "height": 1024,
        "caption": ""
      },
      {
        "src": "/references/kismama/I22A0610_1_480.jpg",
        "lightBox": {
          "src": "/references/kismama/I22A0610_1_1024.jpg",
          "srcSet": [
            {
              "src": "/references/kismama/I22A0610_1_480.jpg",
              "width": 480,
              "height": 320
            },
            {
              "src": "/references/kismama/I22A0610_1_600.jpg",
              "width": 600,
              "height": 400
            },
            {
              "src": "/references/kismama/I22A0610_1_800.jpg",
              "width": 800,
              "height": 533
            },
            {
              "src": "/references/kismama/I22A0610_1_1024.jpg",
              "width": 1024,
              "height": 683
            }
          ]
        },
        "width": 1024,
        "height": 683,
        "caption": ""
      },
      {
        "src": "/references/kismama/I22A0655_480.jpg",
        "lightBox": {
          "src": "/references/kismama/I22A0655_1024.jpg",
          "srcSet": [
            {
              "src": "/references/kismama/I22A0655_480.jpg",
              "width": 480,
              "height": 320
            },
            {
              "src": "/references/kismama/I22A0655_600.jpg",
              "width": 600,
              "height": 400
            },
            {
              "src": "/references/kismama/I22A0655_800.jpg",
              "width": 800,
              "height": 533
            },
            {
              "src": "/references/kismama/I22A0655_1024.jpg",
              "width": 1024,
              "height": 683
            }
          ]
        },
        "width": 1024,
        "height": 683,
        "caption": ""
      },
      {
        "src": "/references/kismama/I22A0770_480.jpg",
        "lightBox": {
          "src": "/references/kismama/I22A0770_1024.jpg",
          "srcSet": [
            {
              "src": "/references/kismama/I22A0770_480.jpg",
              "width": 320,
              "height": 480
            },
            {
              "src": "/references/kismama/I22A0770_600.jpg",
              "width": 400,
              "height": 600
            },
            {
              "src": "/references/kismama/I22A0770_800.jpg",
              "width": 533,
              "height": 800
            },
            {
              "src": "/references/kismama/I22A0770_1024.jpg",
              "width": 683,
              "height": 1024
            }
          ]
        },
        "width": 683,
        "height": 1024,
        "caption": ""
      },
      {
        "src": "/references/kismama/I22A1581_480.jpg",
        "lightBox": {
          "src": "/references/kismama/I22A1581_1024.jpg",
          "srcSet": [
            {
              "src": "/references/kismama/I22A1581_480.jpg",
              "width": 334,
              "height": 480
            },
            {
              "src": "/references/kismama/I22A1581_600.jpg",
              "width": 418,
              "height": 600
            },
            {
              "src": "/references/kismama/I22A1581_800.jpg",
              "width": 557,
              "height": 800
            },
            {
              "src": "/references/kismama/I22A1581_1024.jpg",
              "width": 713,
              "height": 1024
            }
          ]
        },
        "width": 713,
        "height": 1024,
        "caption": ""
      },
      {
        "src": "/references/kismama/I22A1587_480.jpg",
        "lightBox": {
          "src": "/references/kismama/I22A1587_1024.jpg",
          "srcSet": [
            {
              "src": "/references/kismama/I22A1587_480.jpg",
              "width": 343,
              "height": 480
            },
            {
              "src": "/references/kismama/I22A1587_600.jpg",
              "width": 429,
              "height": 600
            },
            {
              "src": "/references/kismama/I22A1587_800.jpg",
              "width": 572,
              "height": 800
            },
            {
              "src": "/references/kismama/I22A1587_1024.jpg",
              "width": 732,
              "height": 1024
            }
          ]
        },
        "width": 732,
        "height": 1024,
        "caption": ""
      },
      {
        "src": "/references/kismama/I22A1610_480.jpg",
        "lightBox": {
          "src": "/references/kismama/I22A1610_1024.jpg",
          "srcSet": [
            {
              "src": "/references/kismama/I22A1610_480.jpg",
              "width": 320,
              "height": 480
            },
            {
              "src": "/references/kismama/I22A1610_600.jpg",
              "width": 400,
              "height": 600
            },
            {
              "src": "/references/kismama/I22A1610_800.jpg",
              "width": 533,
              "height": 800
            },
            {
              "src": "/references/kismama/I22A1610_1024.jpg",
              "width": 683,
              "height": 1024
            }
          ]
        },
        "width": 683,
        "height": 1024,
        "caption": ""
      },
      {
        "src": "/references/kismama/I22A1731_480.jpg",
        "lightBox": {
          "src": "/references/kismama/I22A1731_1024.jpg",
          "srcSet": [
            {
              "src": "/references/kismama/I22A1731_480.jpg",
              "width": 312,
              "height": 480
            },
            {
              "src": "/references/kismama/I22A1731_600.jpg",
              "width": 390,
              "height": 600
            },
            {
              "src": "/references/kismama/I22A1731_800.jpg",
              "width": 519,
              "height": 800
            },
            {
              "src": "/references/kismama/I22A1731_1024.jpg",
              "width": 665,
              "height": 1024
            }
          ]
        },
        "width": 665,
        "height": 1024,
        "caption": ""
      }
    ]
  },
  "jegyesfoto": {
    "images": [
      {
        "src": "/references/jegyesfoto/DSC02405_480.jpg",
        "lightBox": {
          "src": "/references/jegyesfoto/DSC02405_1024.jpg",
          "srcSet": [
            {
              "src": "/references/jegyesfoto/DSC02405_480.jpg",
              "width": 480,
              "height": 320
            },
            {
              "src": "/references/jegyesfoto/DSC02405_600.jpg",
              "width": 600,
              "height": 400
            },
            {
              "src": "/references/jegyesfoto/DSC02405_800.jpg",
              "width": 800,
              "height": 533
            },
            {
              "src": "/references/jegyesfoto/DSC02405_1024.jpg",
              "width": 1024,
              "height": 683
            }
          ]
        },
        "width": 1024,
        "height": 683,
        "caption": ""
      },
      {
        "src": "/references/jegyesfoto/DSC02518_480.jpg",
        "lightBox": {
          "src": "/references/jegyesfoto/DSC02518_1024.jpg",
          "srcSet": [
            {
              "src": "/references/jegyesfoto/DSC02518_480.jpg",
              "width": 480,
              "height": 320
            },
            {
              "src": "/references/jegyesfoto/DSC02518_600.jpg",
              "width": 600,
              "height": 400
            },
            {
              "src": "/references/jegyesfoto/DSC02518_800.jpg",
              "width": 800,
              "height": 533
            },
            {
              "src": "/references/jegyesfoto/DSC02518_1024.jpg",
              "width": 1024,
              "height": 683
            }
          ]
        },
        "width": 1024,
        "height": 683,
        "caption": ""
      },
      {
        "src": "/references/jegyesfoto/DSC02554_480.jpg",
        "lightBox": {
          "src": "/references/jegyesfoto/DSC02554_1024.jpg",
          "srcSet": [
            {
              "src": "/references/jegyesfoto/DSC02554_480.jpg",
              "width": 314,
              "height": 480
            },
            {
              "src": "/references/jegyesfoto/DSC02554_600.jpg",
              "width": 393,
              "height": 600
            },
            {
              "src": "/references/jegyesfoto/DSC02554_800.jpg",
              "width": 524,
              "height": 800
            },
            {
              "src": "/references/jegyesfoto/DSC02554_1024.jpg",
              "width": 670,
              "height": 1024
            }
          ]
        },
        "width": 670,
        "height": 1024,
        "caption": ""
      },
      {
        "src": "/references/jegyesfoto/DSC02699_480.jpg",
        "lightBox": {
          "src": "/references/jegyesfoto/DSC02699_1024.jpg",
          "srcSet": [
            {
              "src": "/references/jegyesfoto/DSC02699_480.jpg",
              "width": 324,
              "height": 480
            },
            {
              "src": "/references/jegyesfoto/DSC02699_600.jpg",
              "width": 405,
              "height": 600
            },
            {
              "src": "/references/jegyesfoto/DSC02699_800.jpg",
              "width": 539,
              "height": 800
            },
            {
              "src": "/references/jegyesfoto/DSC02699_1024.jpg",
              "width": 691,
              "height": 1024
            }
          ]
        },
        "width": 691,
        "height": 1024,
        "caption": ""
      },
      {
        "src": "/references/jegyesfoto/DSC02767_480.jpg",
        "lightBox": {
          "src": "/references/jegyesfoto/DSC02767_1024.jpg",
          "srcSet": [
            {
              "src": "/references/jegyesfoto/DSC02767_480.jpg",
              "width": 320,
              "height": 480
            },
            {
              "src": "/references/jegyesfoto/DSC02767_600.jpg",
              "width": 400,
              "height": 600
            },
            {
              "src": "/references/jegyesfoto/DSC02767_800.jpg",
              "width": 533,
              "height": 800
            },
            {
              "src": "/references/jegyesfoto/DSC02767_1024.jpg",
              "width": 683,
              "height": 1024
            }
          ]
        },
        "width": 683,
        "height": 1024,
        "caption": ""
      },
      {
        "src": "/references/jegyesfoto/DSC02807_480.jpg",
        "lightBox": {
          "src": "/references/jegyesfoto/DSC02807_1024.jpg",
          "srcSet": [
            {
              "src": "/references/jegyesfoto/DSC02807_480.jpg",
              "width": 335,
              "height": 480
            },
            {
              "src": "/references/jegyesfoto/DSC02807_600.jpg",
              "width": 419,
              "height": 600
            },
            {
              "src": "/references/jegyesfoto/DSC02807_800.jpg",
              "width": 558,
              "height": 800
            },
            {
              "src": "/references/jegyesfoto/DSC02807_1024.jpg",
              "width": 714,
              "height": 1024
            }
          ]
        },
        "width": 714,
        "height": 1024,
        "caption": ""
      },
      {
        "src": "/references/jegyesfoto/DSC02826_480.jpg",
        "lightBox": {
          "src": "/references/jegyesfoto/DSC02826_1024.jpg",
          "srcSet": [
            {
              "src": "/references/jegyesfoto/DSC02826_480.jpg",
              "width": 320,
              "height": 480
            },
            {
              "src": "/references/jegyesfoto/DSC02826_600.jpg",
              "width": 400,
              "height": 600
            },
            {
              "src": "/references/jegyesfoto/DSC02826_800.jpg",
              "width": 533,
              "height": 800
            },
            {
              "src": "/references/jegyesfoto/DSC02826_1024.jpg",
              "width": 683,
              "height": 1024
            }
          ]
        },
        "width": 683,
        "height": 1024,
        "caption": ""
      }
    ]
  },
  "portre": {
    "images": [
      {
        "src": "/references/portre/DSC09529_480.jpg",
        "lightBox": {
          "src": "/references/portre/DSC09529_1024.jpg",
          "srcSet": [
            {
              "src": "/references/portre/DSC09529_480.jpg",
              "width": 480,
              "height": 270
            },
            {
              "src": "/references/portre/DSC09529_600.jpg",
              "width": 600,
              "height": 338
            },
            {
              "src": "/references/portre/DSC09529_800.jpg",
              "width": 800,
              "height": 450
            },
            {
              "src": "/references/portre/DSC09529_1024.jpg",
              "width": 1024,
              "height": 576
            }
          ]
        },
        "width": 1024,
        "height": 576,
        "caption": ""
      },
      {
        "src": "/references/portre/DSC09557_480.jpg",
        "lightBox": {
          "src": "/references/portre/DSC09557_1024.jpg",
          "srcSet": [
            {
              "src": "/references/portre/DSC09557_480.jpg",
              "width": 270,
              "height": 480
            },
            {
              "src": "/references/portre/DSC09557_600.jpg",
              "width": 338,
              "height": 600
            },
            {
              "src": "/references/portre/DSC09557_800.jpg",
              "width": 450,
              "height": 800
            },
            {
              "src": "/references/portre/DSC09557_1024.jpg",
              "width": 576,
              "height": 1024
            }
          ]
        },
        "width": 576,
        "height": 1024,
        "caption": ""
      },
      {
        "src": "/references/portre/DSC_9001_480.jpg",
        "lightBox": {
          "src": "/references/portre/DSC_9001_1024.jpg",
          "srcSet": [
            {
              "src": "/references/portre/DSC_9001_480.jpg",
              "width": 320,
              "height": 480
            },
            {
              "src": "/references/portre/DSC_9001_600.jpg",
              "width": 400,
              "height": 600
            },
            {
              "src": "/references/portre/DSC_9001_800.jpg",
              "width": 533,
              "height": 800
            },
            {
              "src": "/references/portre/DSC_9001_1024.jpg",
              "width": 683,
              "height": 1024
            }
          ]
        },
        "width": 683,
        "height": 1024,
        "caption": ""
      },
      {
        "src": "/references/portre/DSC_9044_1_480.jpg",
        "lightBox": {
          "src": "/references/portre/DSC_9044_1_1024.jpg",
          "srcSet": [
            {
              "src": "/references/portre/DSC_9044_1_480.jpg",
              "width": 320,
              "height": 480
            },
            {
              "src": "/references/portre/DSC_9044_1_600.jpg",
              "width": 400,
              "height": 600
            },
            {
              "src": "/references/portre/DSC_9044_1_800.jpg",
              "width": 533,
              "height": 800
            },
            {
              "src": "/references/portre/DSC_9044_1_1024.jpg",
              "width": 683,
              "height": 1024
            }
          ]
        },
        "width": 683,
        "height": 1024,
        "caption": ""
      },
      {
        "src": "/references/portre/I22A0059_480.jpg",
        "lightBox": {
          "src": "/references/portre/I22A0059_1024.jpg",
          "srcSet": [
            {
              "src": "/references/portre/I22A0059_480.jpg",
              "width": 320,
              "height": 480
            },
            {
              "src": "/references/portre/I22A0059_600.jpg",
              "width": 400,
              "height": 600
            },
            {
              "src": "/references/portre/I22A0059_800.jpg",
              "width": 533,
              "height": 800
            },
            {
              "src": "/references/portre/I22A0059_1024.jpg",
              "width": 683,
              "height": 1024
            }
          ]
        },
        "width": 683,
        "height": 1024,
        "caption": ""
      },
      {
        "src": "/references/portre/I22A0073_480.jpg",
        "lightBox": {
          "src": "/references/portre/I22A0073_1024.jpg",
          "srcSet": [
            {
              "src": "/references/portre/I22A0073_480.jpg",
              "width": 320,
              "height": 480
            },
            {
              "src": "/references/portre/I22A0073_600.jpg",
              "width": 400,
              "height": 600
            },
            {
              "src": "/references/portre/I22A0073_800.jpg",
              "width": 533,
              "height": 800
            },
            {
              "src": "/references/portre/I22A0073_1024.jpg",
              "width": 683,
              "height": 1024
            }
          ]
        },
        "width": 683,
        "height": 1024,
        "caption": ""
      },
      {
        "src": "/references/portre/I22A0094_480.jpg",
        "lightBox": {
          "src": "/references/portre/I22A0094_1024.jpg",
          "srcSet": [
            {
              "src": "/references/portre/I22A0094_480.jpg",
              "width": 320,
              "height": 480
            },
            {
              "src": "/references/portre/I22A0094_600.jpg",
              "width": 400,
              "height": 600
            },
            {
              "src": "/references/portre/I22A0094_800.jpg",
              "width": 533,
              "height": 800
            },
            {
              "src": "/references/portre/I22A0094_1024.jpg",
              "width": 683,
              "height": 1024
            }
          ]
        },
        "width": 683,
        "height": 1024,
        "caption": ""
      },
      {
        "src": "/references/portre/I22A0155_480.jpg",
        "lightBox": {
          "src": "/references/portre/I22A0155_1024.jpg",
          "srcSet": [
            {
              "src": "/references/portre/I22A0155_480.jpg",
              "width": 320,
              "height": 480
            },
            {
              "src": "/references/portre/I22A0155_600.jpg",
              "width": 400,
              "height": 600
            },
            {
              "src": "/references/portre/I22A0155_800.jpg",
              "width": 533,
              "height": 800
            },
            {
              "src": "/references/portre/I22A0155_1024.jpg",
              "width": 683,
              "height": 1024
            }
          ]
        },
        "width": 683,
        "height": 1024,
        "caption": ""
      },
      {
        "src": "/references/portre/I22A0228_480.jpg",
        "lightBox": {
          "src": "/references/portre/I22A0228_1024.jpg",
          "srcSet": [
            {
              "src": "/references/portre/I22A0228_480.jpg",
              "width": 320,
              "height": 480
            },
            {
              "src": "/references/portre/I22A0228_600.jpg",
              "width": 400,
              "height": 600
            },
            {
              "src": "/references/portre/I22A0228_800.jpg",
              "width": 533,
              "height": 800
            },
            {
              "src": "/references/portre/I22A0228_1024.jpg",
              "width": 683,
              "height": 1024
            }
          ]
        },
        "width": 683,
        "height": 1024,
        "caption": ""
      },
      {
        "src": "/references/portre/I22A0243_480.jpg",
        "lightBox": {
          "src": "/references/portre/I22A0243_1024.jpg",
          "srcSet": [
            {
              "src": "/references/portre/I22A0243_480.jpg",
              "width": 320,
              "height": 480
            },
            {
              "src": "/references/portre/I22A0243_600.jpg",
              "width": 400,
              "height": 600
            },
            {
              "src": "/references/portre/I22A0243_800.jpg",
              "width": 533,
              "height": 800
            },
            {
              "src": "/references/portre/I22A0243_1024.jpg",
              "width": 683,
              "height": 1024
            }
          ]
        },
        "width": 683,
        "height": 1024,
        "caption": ""
      },
      {
        "src": "/references/portre/I22A0350_480.jpg",
        "lightBox": {
          "src": "/references/portre/I22A0350_1024.jpg",
          "srcSet": [
            {
              "src": "/references/portre/I22A0350_480.jpg",
              "width": 311,
              "height": 480
            },
            {
              "src": "/references/portre/I22A0350_600.jpg",
              "width": 389,
              "height": 600
            },
            {
              "src": "/references/portre/I22A0350_800.jpg",
              "width": 518,
              "height": 800
            },
            {
              "src": "/references/portre/I22A0350_1024.jpg",
              "width": 663,
              "height": 1024
            }
          ]
        },
        "width": 663,
        "height": 1024,
        "caption": ""
      },
      {
        "src": "/references/portre/I22A0420_480.jpg",
        "lightBox": {
          "src": "/references/portre/I22A0420_1024.jpg",
          "srcSet": [
            {
              "src": "/references/portre/I22A0420_480.jpg",
              "width": 320,
              "height": 480
            },
            {
              "src": "/references/portre/I22A0420_600.jpg",
              "width": 400,
              "height": 600
            },
            {
              "src": "/references/portre/I22A0420_800.jpg",
              "width": 533,
              "height": 800
            },
            {
              "src": "/references/portre/I22A0420_1024.jpg",
              "width": 683,
              "height": 1024
            }
          ]
        },
        "width": 683,
        "height": 1024,
        "caption": ""
      },
      {
        "src": "/references/portre/I22A0508_480.jpg",
        "lightBox": {
          "src": "/references/portre/I22A0508_1024.jpg",
          "srcSet": [
            {
              "src": "/references/portre/I22A0508_480.jpg",
              "width": 480,
              "height": 320
            },
            {
              "src": "/references/portre/I22A0508_600.jpg",
              "width": 600,
              "height": 400
            },
            {
              "src": "/references/portre/I22A0508_800.jpg",
              "width": 800,
              "height": 533
            },
            {
              "src": "/references/portre/I22A0508_1024.jpg",
              "width": 1024,
              "height": 683
            }
          ]
        },
        "width": 1024,
        "height": 683,
        "caption": ""
      },
      {
        "src": "/references/portre/I22A0513_480.jpg",
        "lightBox": {
          "src": "/references/portre/I22A0513_1024.jpg",
          "srcSet": [
            {
              "src": "/references/portre/I22A0513_480.jpg",
              "width": 320,
              "height": 480
            },
            {
              "src": "/references/portre/I22A0513_600.jpg",
              "width": 400,
              "height": 600
            },
            {
              "src": "/references/portre/I22A0513_800.jpg",
              "width": 533,
              "height": 800
            },
            {
              "src": "/references/portre/I22A0513_1024.jpg",
              "width": 683,
              "height": 1024
            }
          ]
        },
        "width": 683,
        "height": 1024,
        "caption": ""
      },
      {
        "src": "/references/portre/I22A0524_480.jpg",
        "lightBox": {
          "src": "/references/portre/I22A0524_1024.jpg",
          "srcSet": [
            {
              "src": "/references/portre/I22A0524_480.jpg",
              "width": 320,
              "height": 480
            },
            {
              "src": "/references/portre/I22A0524_600.jpg",
              "width": 400,
              "height": 600
            },
            {
              "src": "/references/portre/I22A0524_800.jpg",
              "width": 533,
              "height": 800
            },
            {
              "src": "/references/portre/I22A0524_1024.jpg",
              "width": 683,
              "height": 1024
            }
          ]
        },
        "width": 683,
        "height": 1024,
        "caption": ""
      },
      {
        "src": "/references/portre/I22A0584_480.jpg",
        "lightBox": {
          "src": "/references/portre/I22A0584_1024.jpg",
          "srcSet": [
            {
              "src": "/references/portre/I22A0584_480.jpg",
              "width": 480,
              "height": 320
            },
            {
              "src": "/references/portre/I22A0584_600.jpg",
              "width": 600,
              "height": 400
            },
            {
              "src": "/references/portre/I22A0584_800.jpg",
              "width": 800,
              "height": 533
            },
            {
              "src": "/references/portre/I22A0584_1024.jpg",
              "width": 1024,
              "height": 683
            }
          ]
        },
        "width": 1024,
        "height": 683,
        "caption": ""
      },
      {
        "src": "/references/portre/IMG_0650_480.jpg",
        "lightBox": {
          "src": "/references/portre/IMG_0650_1024.jpg",
          "srcSet": [
            {
              "src": "/references/portre/IMG_0650_480.jpg",
              "width": 320,
              "height": 480
            },
            {
              "src": "/references/portre/IMG_0650_600.jpg",
              "width": 400,
              "height": 600
            },
            {
              "src": "/references/portre/IMG_0650_800.jpg",
              "width": 533,
              "height": 800
            },
            {
              "src": "/references/portre/IMG_0650_1024.jpg",
              "width": 683,
              "height": 1024
            }
          ]
        },
        "width": 683,
        "height": 1024,
        "caption": ""
      },
      {
        "src": "/references/portre/IMG_0748_480.jpg",
        "lightBox": {
          "src": "/references/portre/IMG_0748_1024.jpg",
          "srcSet": [
            {
              "src": "/references/portre/IMG_0748_480.jpg",
              "width": 480,
              "height": 320
            },
            {
              "src": "/references/portre/IMG_0748_600.jpg",
              "width": 600,
              "height": 400
            },
            {
              "src": "/references/portre/IMG_0748_800.jpg",
              "width": 800,
              "height": 533
            },
            {
              "src": "/references/portre/IMG_0748_1024.jpg",
              "width": 1024,
              "height": 683
            }
          ]
        },
        "width": 1024,
        "height": 683,
        "caption": ""
      },
      {
        "src": "/references/portre/IMG_0764_480.jpg",
        "lightBox": {
          "src": "/references/portre/IMG_0764_1024.jpg",
          "srcSet": [
            {
              "src": "/references/portre/IMG_0764_480.jpg",
              "width": 320,
              "height": 480
            },
            {
              "src": "/references/portre/IMG_0764_600.jpg",
              "width": 400,
              "height": 600
            },
            {
              "src": "/references/portre/IMG_0764_800.jpg",
              "width": 533,
              "height": 800
            },
            {
              "src": "/references/portre/IMG_0764_1024.jpg",
              "width": 683,
              "height": 1024
            }
          ]
        },
        "width": 683,
        "height": 1024,
        "caption": ""
      },
      {
        "src": "/references/portre/IMG_0858_480.jpg",
        "lightBox": {
          "src": "/references/portre/IMG_0858_1024.jpg",
          "srcSet": [
            {
              "src": "/references/portre/IMG_0858_480.jpg",
              "width": 480,
              "height": 320
            },
            {
              "src": "/references/portre/IMG_0858_600.jpg",
              "width": 600,
              "height": 400
            },
            {
              "src": "/references/portre/IMG_0858_800.jpg",
              "width": 800,
              "height": 533
            },
            {
              "src": "/references/portre/IMG_0858_1024.jpg",
              "width": 1024,
              "height": 683
            }
          ]
        },
        "width": 1024,
        "height": 683,
        "caption": ""
      },
      {
        "src": "/references/portre/IMG_8418_480.jpg",
        "lightBox": {
          "src": "/references/portre/IMG_8418_1024.jpg",
          "srcSet": [
            {
              "src": "/references/portre/IMG_8418_480.jpg",
              "width": 480,
              "height": 320
            },
            {
              "src": "/references/portre/IMG_8418_600.jpg",
              "width": 600,
              "height": 400
            },
            {
              "src": "/references/portre/IMG_8418_800.jpg",
              "width": 800,
              "height": 533
            },
            {
              "src": "/references/portre/IMG_8418_1024.jpg",
              "width": 1024,
              "height": 683
            }
          ]
        },
        "width": 1024,
        "height": 683,
        "caption": ""
      },
      {
        "src": "/references/portre/IMG_8856_480.jpg",
        "lightBox": {
          "src": "/references/portre/IMG_8856_1024.jpg",
          "srcSet": [
            {
              "src": "/references/portre/IMG_8856_480.jpg",
              "width": 480,
              "height": 392
            },
            {
              "src": "/references/portre/IMG_8856_600.jpg",
              "width": 600,
              "height": 490
            },
            {
              "src": "/references/portre/IMG_8856_800.jpg",
              "width": 800,
              "height": 653
            },
            {
              "src": "/references/portre/IMG_8856_1024.jpg",
              "width": 1024,
              "height": 836
            }
          ]
        },
        "width": 1024,
        "height": 836,
        "caption": ""
      },
      {
        "src": "/references/portre/IMG_8993_480.jpg",
        "lightBox": {
          "src": "/references/portre/IMG_8993_1024.jpg",
          "srcSet": [
            {
              "src": "/references/portre/IMG_8993_480.jpg",
              "width": 320,
              "height": 480
            },
            {
              "src": "/references/portre/IMG_8993_600.jpg",
              "width": 400,
              "height": 600
            },
            {
              "src": "/references/portre/IMG_8993_800.jpg",
              "width": 533,
              "height": 800
            },
            {
              "src": "/references/portre/IMG_8993_1024.jpg",
              "width": 683,
              "height": 1024
            }
          ]
        },
        "width": 683,
        "height": 1024,
        "caption": ""
      },
      {
        "src": "/references/portre/IMG_9093_2_480.jpg",
        "lightBox": {
          "src": "/references/portre/IMG_9093_2_1024.jpg",
          "srcSet": [
            {
              "src": "/references/portre/IMG_9093_2_480.jpg",
              "width": 480,
              "height": 326
            },
            {
              "src": "/references/portre/IMG_9093_2_600.jpg",
              "width": 600,
              "height": 407
            },
            {
              "src": "/references/portre/IMG_9093_2_800.jpg",
              "width": 800,
              "height": 543
            },
            {
              "src": "/references/portre/IMG_9093_2_1024.jpg",
              "width": 1024,
              "height": 695
            }
          ]
        },
        "width": 1024,
        "height": 695,
        "caption": ""
      },
      {
        "src": "/references/portre/IMG_9183_1_480.jpg",
        "lightBox": {
          "src": "/references/portre/IMG_9183_1_1024.jpg",
          "srcSet": [
            {
              "src": "/references/portre/IMG_9183_1_480.jpg",
              "width": 480,
              "height": 320
            },
            {
              "src": "/references/portre/IMG_9183_1_600.jpg",
              "width": 600,
              "height": 400
            },
            {
              "src": "/references/portre/IMG_9183_1_800.jpg",
              "width": 800,
              "height": 533
            },
            {
              "src": "/references/portre/IMG_9183_1_1024.jpg",
              "width": 1024,
              "height": 683
            }
          ]
        },
        "width": 1024,
        "height": 683,
        "caption": ""
      },
      {
        "src": "/references/portre/IMG_9210_480.jpg",
        "lightBox": {
          "src": "/references/portre/IMG_9210_1024.jpg",
          "srcSet": [
            {
              "src": "/references/portre/IMG_9210_480.jpg",
              "width": 320,
              "height": 480
            },
            {
              "src": "/references/portre/IMG_9210_600.jpg",
              "width": 400,
              "height": 600
            },
            {
              "src": "/references/portre/IMG_9210_800.jpg",
              "width": 533,
              "height": 800
            },
            {
              "src": "/references/portre/IMG_9210_1024.jpg",
              "width": 683,
              "height": 1024
            }
          ]
        },
        "width": 683,
        "height": 1024,
        "caption": ""
      },
      {
        "src": "/references/portre/IMG_9376_480.jpg",
        "lightBox": {
          "src": "/references/portre/IMG_9376_1024.jpg",
          "srcSet": [
            {
              "src": "/references/portre/IMG_9376_480.jpg",
              "width": 330,
              "height": 480
            },
            {
              "src": "/references/portre/IMG_9376_600.jpg",
              "width": 413,
              "height": 600
            },
            {
              "src": "/references/portre/IMG_9376_800.jpg",
              "width": 550,
              "height": 800
            },
            {
              "src": "/references/portre/IMG_9376_1024.jpg",
              "width": 704,
              "height": 1024
            }
          ]
        },
        "width": 704,
        "height": 1024,
        "caption": ""
      }
    ]
  },
  "wedding": {
    "images": [
      {
        "src": "/references/wedding/DSC00009_480.jpg",
        "lightBox": {
          "src": "/references/wedding/DSC00009_1024.jpg",
          "srcSet": [
            {
              "src": "/references/wedding/DSC00009_480.jpg",
              "width": 308,
              "height": 480
            },
            {
              "src": "/references/wedding/DSC00009_600.jpg",
              "width": 385,
              "height": 600
            },
            {
              "src": "/references/wedding/DSC00009_800.jpg",
              "width": 513,
              "height": 800
            },
            {
              "src": "/references/wedding/DSC00009_1024.jpg",
              "width": 657,
              "height": 1024
            }
          ]
        },
        "width": 657,
        "height": 1024,
        "caption": ""
      },
      {
        "src": "/references/wedding/DSC00017_480.jpg",
        "lightBox": {
          "src": "/references/wedding/DSC00017_1024.jpg",
          "srcSet": [
            {
              "src": "/references/wedding/DSC00017_480.jpg",
              "width": 480,
              "height": 307
            },
            {
              "src": "/references/wedding/DSC00017_600.jpg",
              "width": 600,
              "height": 384
            },
            {
              "src": "/references/wedding/DSC00017_800.jpg",
              "width": 800,
              "height": 512
            },
            {
              "src": "/references/wedding/DSC00017_1024.jpg",
              "width": 1024,
              "height": 655
            }
          ]
        },
        "width": 1024,
        "height": 655,
        "caption": ""
      },
      {
        "src": "/references/wedding/DSC00026_480.jpg",
        "lightBox": {
          "src": "/references/wedding/DSC00026_1024.jpg",
          "srcSet": [
            {
              "src": "/references/wedding/DSC00026_480.jpg",
              "width": 480,
              "height": 270
            },
            {
              "src": "/references/wedding/DSC00026_600.jpg",
              "width": 600,
              "height": 337
            },
            {
              "src": "/references/wedding/DSC00026_800.jpg",
              "width": 800,
              "height": 449
            },
            {
              "src": "/references/wedding/DSC00026_1024.jpg",
              "width": 1024,
              "height": 575
            }
          ]
        },
        "width": 1024,
        "height": 575,
        "caption": ""
      },
      {
        "src": "/references/wedding/DSC00035_480.jpg",
        "lightBox": {
          "src": "/references/wedding/DSC00035_1024.jpg",
          "srcSet": [
            {
              "src": "/references/wedding/DSC00035_480.jpg",
              "width": 480,
              "height": 270
            },
            {
              "src": "/references/wedding/DSC00035_600.jpg",
              "width": 600,
              "height": 338
            },
            {
              "src": "/references/wedding/DSC00035_800.jpg",
              "width": 800,
              "height": 450
            },
            {
              "src": "/references/wedding/DSC00035_1024.jpg",
              "width": 1024,
              "height": 576
            }
          ]
        },
        "width": 1024,
        "height": 576,
        "caption": ""
      },
      {
        "src": "/references/wedding/DSC00073_480.jpg",
        "lightBox": {
          "src": "/references/wedding/DSC00073_1024.jpg",
          "srcSet": [
            {
              "src": "/references/wedding/DSC00073_480.jpg",
              "width": 480,
              "height": 268
            },
            {
              "src": "/references/wedding/DSC00073_600.jpg",
              "width": 600,
              "height": 335
            },
            {
              "src": "/references/wedding/DSC00073_800.jpg",
              "width": 800,
              "height": 447
            },
            {
              "src": "/references/wedding/DSC00073_1024.jpg",
              "width": 1024,
              "height": 572
            }
          ]
        },
        "width": 1024,
        "height": 572,
        "caption": ""
      },
      {
        "src": "/references/wedding/DSC00103_480.jpg",
        "lightBox": {
          "src": "/references/wedding/DSC00103_1024.jpg",
          "srcSet": [
            {
              "src": "/references/wedding/DSC00103_480.jpg",
              "width": 480,
              "height": 297
            },
            {
              "src": "/references/wedding/DSC00103_600.jpg",
              "width": 600,
              "height": 371
            },
            {
              "src": "/references/wedding/DSC00103_800.jpg",
              "width": 800,
              "height": 494
            },
            {
              "src": "/references/wedding/DSC00103_1024.jpg",
              "width": 1024,
              "height": 633
            }
          ]
        },
        "width": 1024,
        "height": 633,
        "caption": ""
      },
      {
        "src": "/references/wedding/DSC00162_480.jpg",
        "lightBox": {
          "src": "/references/wedding/DSC00162_1024.jpg",
          "srcSet": [
            {
              "src": "/references/wedding/DSC00162_480.jpg",
              "width": 480,
              "height": 270
            },
            {
              "src": "/references/wedding/DSC00162_600.jpg",
              "width": 600,
              "height": 338
            },
            {
              "src": "/references/wedding/DSC00162_800.jpg",
              "width": 800,
              "height": 450
            },
            {
              "src": "/references/wedding/DSC00162_1024.jpg",
              "width": 1024,
              "height": 576
            }
          ]
        },
        "width": 1024,
        "height": 576,
        "caption": ""
      },
      {
        "src": "/references/wedding/DSC00707_480.jpg",
        "lightBox": {
          "src": "/references/wedding/DSC00707_1024.jpg",
          "srcSet": [
            {
              "src": "/references/wedding/DSC00707_480.jpg",
              "width": 309,
              "height": 480
            },
            {
              "src": "/references/wedding/DSC00707_600.jpg",
              "width": 386,
              "height": 600
            },
            {
              "src": "/references/wedding/DSC00707_800.jpg",
              "width": 515,
              "height": 800
            },
            {
              "src": "/references/wedding/DSC00707_1024.jpg",
              "width": 659,
              "height": 1024
            }
          ]
        },
        "width": 659,
        "height": 1024,
        "caption": ""
      },
      {
        "src": "/references/wedding/DSC00725_480.jpg",
        "lightBox": {
          "src": "/references/wedding/DSC00725_1024.jpg",
          "srcSet": [
            {
              "src": "/references/wedding/DSC00725_480.jpg",
              "width": 270,
              "height": 480
            },
            {
              "src": "/references/wedding/DSC00725_600.jpg",
              "width": 338,
              "height": 600
            },
            {
              "src": "/references/wedding/DSC00725_800.jpg",
              "width": 450,
              "height": 800
            },
            {
              "src": "/references/wedding/DSC00725_1024.jpg",
              "width": 576,
              "height": 1024
            }
          ]
        },
        "width": 576,
        "height": 1024,
        "caption": ""
      },
      {
        "src": "/references/wedding/DSC00772_1_480.jpg",
        "lightBox": {
          "src": "/references/wedding/DSC00772_1_1024.jpg",
          "srcSet": [
            {
              "src": "/references/wedding/DSC00772_1_480.jpg",
              "width": 480,
              "height": 320
            },
            {
              "src": "/references/wedding/DSC00772_1_600.jpg",
              "width": 600,
              "height": 400
            },
            {
              "src": "/references/wedding/DSC00772_1_800.jpg",
              "width": 800,
              "height": 533
            },
            {
              "src": "/references/wedding/DSC00772_1_1024.jpg",
              "width": 1024,
              "height": 683
            }
          ]
        },
        "width": 1024,
        "height": 683,
        "caption": ""
      },
      {
        "src": "/references/wedding/DSC00776_480.jpg",
        "lightBox": {
          "src": "/references/wedding/DSC00776_1024.jpg",
          "srcSet": [
            {
              "src": "/references/wedding/DSC00776_480.jpg",
              "width": 320,
              "height": 480
            },
            {
              "src": "/references/wedding/DSC00776_600.jpg",
              "width": 400,
              "height": 600
            },
            {
              "src": "/references/wedding/DSC00776_800.jpg",
              "width": 533,
              "height": 800
            },
            {
              "src": "/references/wedding/DSC00776_1024.jpg",
              "width": 683,
              "height": 1024
            }
          ]
        },
        "width": 683,
        "height": 1024,
        "caption": ""
      },
      {
        "src": "/references/wedding/DSC00812_480.jpg",
        "lightBox": {
          "src": "/references/wedding/DSC00812_1024.jpg",
          "srcSet": [
            {
              "src": "/references/wedding/DSC00812_480.jpg",
              "width": 270,
              "height": 480
            },
            {
              "src": "/references/wedding/DSC00812_600.jpg",
              "width": 338,
              "height": 600
            },
            {
              "src": "/references/wedding/DSC00812_800.jpg",
              "width": 450,
              "height": 800
            },
            {
              "src": "/references/wedding/DSC00812_1024.jpg",
              "width": 576,
              "height": 1024
            }
          ]
        },
        "width": 576,
        "height": 1024,
        "caption": ""
      },
      {
        "src": "/references/wedding/DSC00820_480.jpg",
        "lightBox": {
          "src": "/references/wedding/DSC00820_1024.jpg",
          "srcSet": [
            {
              "src": "/references/wedding/DSC00820_480.jpg",
              "width": 480,
              "height": 270
            },
            {
              "src": "/references/wedding/DSC00820_600.jpg",
              "width": 600,
              "height": 338
            },
            {
              "src": "/references/wedding/DSC00820_800.jpg",
              "width": 800,
              "height": 450
            },
            {
              "src": "/references/wedding/DSC00820_1024.jpg",
              "width": 1024,
              "height": 576
            }
          ]
        },
        "width": 1024,
        "height": 576,
        "caption": ""
      },
      {
        "src": "/references/wedding/DSC00910_480.jpg",
        "lightBox": {
          "src": "/references/wedding/DSC00910_1024.jpg",
          "srcSet": [
            {
              "src": "/references/wedding/DSC00910_480.jpg",
              "width": 320,
              "height": 480
            },
            {
              "src": "/references/wedding/DSC00910_600.jpg",
              "width": 400,
              "height": 600
            },
            {
              "src": "/references/wedding/DSC00910_800.jpg",
              "width": 533,
              "height": 800
            },
            {
              "src": "/references/wedding/DSC00910_1024.jpg",
              "width": 683,
              "height": 1024
            }
          ]
        },
        "width": 683,
        "height": 1024,
        "caption": ""
      },
      {
        "src": "/references/wedding/DSC00947_480.jpg",
        "lightBox": {
          "src": "/references/wedding/DSC00947_1024.jpg",
          "srcSet": [
            {
              "src": "/references/wedding/DSC00947_480.jpg",
              "width": 320,
              "height": 480
            },
            {
              "src": "/references/wedding/DSC00947_600.jpg",
              "width": 400,
              "height": 600
            },
            {
              "src": "/references/wedding/DSC00947_800.jpg",
              "width": 533,
              "height": 800
            },
            {
              "src": "/references/wedding/DSC00947_1024.jpg",
              "width": 683,
              "height": 1024
            }
          ]
        },
        "width": 683,
        "height": 1024,
        "caption": ""
      },
      {
        "src": "/references/wedding/DSC00953_480.jpg",
        "lightBox": {
          "src": "/references/wedding/DSC00953_1024.jpg",
          "srcSet": [
            {
              "src": "/references/wedding/DSC00953_480.jpg",
              "width": 480,
              "height": 320
            },
            {
              "src": "/references/wedding/DSC00953_600.jpg",
              "width": 600,
              "height": 400
            },
            {
              "src": "/references/wedding/DSC00953_800.jpg",
              "width": 800,
              "height": 533
            },
            {
              "src": "/references/wedding/DSC00953_1024.jpg",
              "width": 1024,
              "height": 683
            }
          ]
        },
        "width": 1024,
        "height": 683,
        "caption": ""
      },
      {
        "src": "/references/wedding/DSC00970_480.jpg",
        "lightBox": {
          "src": "/references/wedding/DSC00970_1024.jpg",
          "srcSet": [
            {
              "src": "/references/wedding/DSC00970_480.jpg",
              "width": 320,
              "height": 480
            },
            {
              "src": "/references/wedding/DSC00970_600.jpg",
              "width": 400,
              "height": 600
            },
            {
              "src": "/references/wedding/DSC00970_800.jpg",
              "width": 533,
              "height": 800
            },
            {
              "src": "/references/wedding/DSC00970_1024.jpg",
              "width": 683,
              "height": 1024
            }
          ]
        },
        "width": 683,
        "height": 1024,
        "caption": ""
      },
      {
        "src": "/references/wedding/DSC02139_480.jpg",
        "lightBox": {
          "src": "/references/wedding/DSC02139_1024.jpg",
          "srcSet": [
            {
              "src": "/references/wedding/DSC02139_480.jpg",
              "width": 333,
              "height": 480
            },
            {
              "src": "/references/wedding/DSC02139_600.jpg",
              "width": 416,
              "height": 600
            },
            {
              "src": "/references/wedding/DSC02139_800.jpg",
              "width": 555,
              "height": 800
            },
            {
              "src": "/references/wedding/DSC02139_1024.jpg",
              "width": 711,
              "height": 1024
            }
          ]
        },
        "width": 711,
        "height": 1024,
        "caption": ""
      },
      {
        "src": "/references/wedding/DSC02320_480.jpg",
        "lightBox": {
          "src": "/references/wedding/DSC02320_1024.jpg",
          "srcSet": [
            {
              "src": "/references/wedding/DSC02320_480.jpg",
              "width": 480,
              "height": 283
            },
            {
              "src": "/references/wedding/DSC02320_600.jpg",
              "width": 600,
              "height": 354
            },
            {
              "src": "/references/wedding/DSC02320_800.jpg",
              "width": 800,
              "height": 472
            },
            {
              "src": "/references/wedding/DSC02320_1024.jpg",
              "width": 1024,
              "height": 604
            }
          ]
        },
        "width": 1024,
        "height": 604,
        "caption": ""
      },
      {
        "src": "/references/wedding/DSC02326_480.jpg",
        "lightBox": {
          "src": "/references/wedding/DSC02326_1024.jpg",
          "srcSet": [
            {
              "src": "/references/wedding/DSC02326_480.jpg",
              "width": 480,
              "height": 270
            },
            {
              "src": "/references/wedding/DSC02326_600.jpg",
              "width": 600,
              "height": 338
            },
            {
              "src": "/references/wedding/DSC02326_800.jpg",
              "width": 800,
              "height": 450
            },
            {
              "src": "/references/wedding/DSC02326_1024.jpg",
              "width": 1024,
              "height": 576
            }
          ]
        },
        "width": 1024,
        "height": 576,
        "caption": ""
      },
      {
        "src": "/references/wedding/DSC02330_480.jpg",
        "lightBox": {
          "src": "/references/wedding/DSC02330_1024.jpg",
          "srcSet": [
            {
              "src": "/references/wedding/DSC02330_480.jpg",
              "width": 480,
              "height": 308
            },
            {
              "src": "/references/wedding/DSC02330_600.jpg",
              "width": 600,
              "height": 385
            },
            {
              "src": "/references/wedding/DSC02330_800.jpg",
              "width": 800,
              "height": 514
            },
            {
              "src": "/references/wedding/DSC02330_1024.jpg",
              "width": 1024,
              "height": 658
            }
          ]
        },
        "width": 1024,
        "height": 658,
        "caption": ""
      },
      {
        "src": "/references/wedding/DSC02337_480.jpg",
        "lightBox": {
          "src": "/references/wedding/DSC02337_1024.jpg",
          "srcSet": [
            {
              "src": "/references/wedding/DSC02337_480.jpg",
              "width": 480,
              "height": 295
            },
            {
              "src": "/references/wedding/DSC02337_600.jpg",
              "width": 600,
              "height": 368
            },
            {
              "src": "/references/wedding/DSC02337_800.jpg",
              "width": 800,
              "height": 491
            },
            {
              "src": "/references/wedding/DSC02337_1024.jpg",
              "width": 1024,
              "height": 629
            }
          ]
        },
        "width": 1024,
        "height": 629,
        "caption": ""
      },
      {
        "src": "/references/wedding/DSC02979_480.jpg",
        "lightBox": {
          "src": "/references/wedding/DSC02979_1024.jpg",
          "srcSet": [
            {
              "src": "/references/wedding/DSC02979_480.jpg",
              "width": 320,
              "height": 480
            },
            {
              "src": "/references/wedding/DSC02979_600.jpg",
              "width": 400,
              "height": 600
            },
            {
              "src": "/references/wedding/DSC02979_800.jpg",
              "width": 533,
              "height": 800
            },
            {
              "src": "/references/wedding/DSC02979_1024.jpg",
              "width": 683,
              "height": 1024
            }
          ]
        },
        "width": 683,
        "height": 1024,
        "caption": ""
      },
      {
        "src": "/references/wedding/DSC02994_480.jpg",
        "lightBox": {
          "src": "/references/wedding/DSC02994_1024.jpg",
          "srcSet": [
            {
              "src": "/references/wedding/DSC02994_480.jpg",
              "width": 480,
              "height": 320
            },
            {
              "src": "/references/wedding/DSC02994_600.jpg",
              "width": 600,
              "height": 400
            },
            {
              "src": "/references/wedding/DSC02994_800.jpg",
              "width": 800,
              "height": 533
            },
            {
              "src": "/references/wedding/DSC02994_1024.jpg",
              "width": 1024,
              "height": 683
            }
          ]
        },
        "width": 1024,
        "height": 683,
        "caption": ""
      },
      {
        "src": "/references/wedding/DSC03035_480.jpg",
        "lightBox": {
          "src": "/references/wedding/DSC03035_1024.jpg",
          "srcSet": [
            {
              "src": "/references/wedding/DSC03035_480.jpg",
              "width": 480,
              "height": 272
            },
            {
              "src": "/references/wedding/DSC03035_600.jpg",
              "width": 600,
              "height": 340
            },
            {
              "src": "/references/wedding/DSC03035_800.jpg",
              "width": 800,
              "height": 453
            },
            {
              "src": "/references/wedding/DSC03035_1024.jpg",
              "width": 1024,
              "height": 579
            }
          ]
        },
        "width": 1024,
        "height": 579,
        "caption": ""
      },
      {
        "src": "/references/wedding/DSC03075_480.jpg",
        "lightBox": {
          "src": "/references/wedding/DSC03075_1024.jpg",
          "srcSet": [
            {
              "src": "/references/wedding/DSC03075_480.jpg",
              "width": 345,
              "height": 480
            },
            {
              "src": "/references/wedding/DSC03075_600.jpg",
              "width": 431,
              "height": 600
            },
            {
              "src": "/references/wedding/DSC03075_800.jpg",
              "width": 574,
              "height": 800
            },
            {
              "src": "/references/wedding/DSC03075_1024.jpg",
              "width": 735,
              "height": 1024
            }
          ]
        },
        "width": 735,
        "height": 1024,
        "caption": ""
      },
      {
        "src": "/references/wedding/DSC03088_480.jpg",
        "lightBox": {
          "src": "/references/wedding/DSC03088_1024.jpg",
          "srcSet": [
            {
              "src": "/references/wedding/DSC03088_480.jpg",
              "width": 480,
              "height": 317
            },
            {
              "src": "/references/wedding/DSC03088_600.jpg",
              "width": 600,
              "height": 396
            },
            {
              "src": "/references/wedding/DSC03088_800.jpg",
              "width": 800,
              "height": 528
            },
            {
              "src": "/references/wedding/DSC03088_1024.jpg",
              "width": 1024,
              "height": 676
            }
          ]
        },
        "width": 1024,
        "height": 676,
        "caption": ""
      },
      {
        "src": "/references/wedding/DSC03099_480.jpg",
        "lightBox": {
          "src": "/references/wedding/DSC03099_1024.jpg",
          "srcSet": [
            {
              "src": "/references/wedding/DSC03099_480.jpg",
              "width": 320,
              "height": 480
            },
            {
              "src": "/references/wedding/DSC03099_600.jpg",
              "width": 400,
              "height": 600
            },
            {
              "src": "/references/wedding/DSC03099_800.jpg",
              "width": 533,
              "height": 800
            },
            {
              "src": "/references/wedding/DSC03099_1024.jpg",
              "width": 683,
              "height": 1024
            }
          ]
        },
        "width": 683,
        "height": 1024,
        "caption": ""
      },
      {
        "src": "/references/wedding/DSC03104_480.jpg",
        "lightBox": {
          "src": "/references/wedding/DSC03104_1024.jpg",
          "srcSet": [
            {
              "src": "/references/wedding/DSC03104_480.jpg",
              "width": 480,
              "height": 317
            },
            {
              "src": "/references/wedding/DSC03104_600.jpg",
              "width": 600,
              "height": 396
            },
            {
              "src": "/references/wedding/DSC03104_800.jpg",
              "width": 800,
              "height": 528
            },
            {
              "src": "/references/wedding/DSC03104_1024.jpg",
              "width": 1024,
              "height": 676
            }
          ]
        },
        "width": 1024,
        "height": 676,
        "caption": ""
      },
      {
        "src": "/references/wedding/DSC03179_480.jpg",
        "lightBox": {
          "src": "/references/wedding/DSC03179_1024.jpg",
          "srcSet": [
            {
              "src": "/references/wedding/DSC03179_480.jpg",
              "width": 480,
              "height": 317
            },
            {
              "src": "/references/wedding/DSC03179_600.jpg",
              "width": 600,
              "height": 396
            },
            {
              "src": "/references/wedding/DSC03179_800.jpg",
              "width": 800,
              "height": 528
            },
            {
              "src": "/references/wedding/DSC03179_1024.jpg",
              "width": 1024,
              "height": 676
            }
          ]
        },
        "width": 1024,
        "height": 676,
        "caption": ""
      },
      {
        "src": "/references/wedding/DSC03179_1_480.jpg",
        "lightBox": {
          "src": "/references/wedding/DSC03179_1_1024.jpg",
          "srcSet": [
            {
              "src": "/references/wedding/DSC03179_1_480.jpg",
              "width": 480,
              "height": 320
            },
            {
              "src": "/references/wedding/DSC03179_1_600.jpg",
              "width": 600,
              "height": 400
            },
            {
              "src": "/references/wedding/DSC03179_1_800.jpg",
              "width": 800,
              "height": 533
            },
            {
              "src": "/references/wedding/DSC03179_1_1024.jpg",
              "width": 1024,
              "height": 683
            }
          ]
        },
        "width": 1024,
        "height": 683,
        "caption": ""
      },
      {
        "src": "/references/wedding/DSC03181_480.jpg",
        "lightBox": {
          "src": "/references/wedding/DSC03181_1024.jpg",
          "srcSet": [
            {
              "src": "/references/wedding/DSC03181_480.jpg",
              "width": 480,
              "height": 320
            },
            {
              "src": "/references/wedding/DSC03181_600.jpg",
              "width": 600,
              "height": 400
            },
            {
              "src": "/references/wedding/DSC03181_800.jpg",
              "width": 800,
              "height": 533
            },
            {
              "src": "/references/wedding/DSC03181_1024.jpg",
              "width": 1024,
              "height": 683
            }
          ]
        },
        "width": 1024,
        "height": 683,
        "caption": ""
      },
      {
        "src": "/references/wedding/DSC03326_480.jpg",
        "lightBox": {
          "src": "/references/wedding/DSC03326_1024.jpg",
          "srcSet": [
            {
              "src": "/references/wedding/DSC03326_480.jpg",
              "width": 480,
              "height": 320
            },
            {
              "src": "/references/wedding/DSC03326_600.jpg",
              "width": 600,
              "height": 400
            },
            {
              "src": "/references/wedding/DSC03326_800.jpg",
              "width": 800,
              "height": 533
            },
            {
              "src": "/references/wedding/DSC03326_1024.jpg",
              "width": 1024,
              "height": 683
            }
          ]
        },
        "width": 1024,
        "height": 683,
        "caption": ""
      },
      {
        "src": "/references/wedding/DSC03341_480.jpg",
        "lightBox": {
          "src": "/references/wedding/DSC03341_1024.jpg",
          "srcSet": [
            {
              "src": "/references/wedding/DSC03341_480.jpg",
              "width": 480,
              "height": 320
            },
            {
              "src": "/references/wedding/DSC03341_600.jpg",
              "width": 600,
              "height": 400
            },
            {
              "src": "/references/wedding/DSC03341_800.jpg",
              "width": 800,
              "height": 533
            },
            {
              "src": "/references/wedding/DSC03341_1024.jpg",
              "width": 1024,
              "height": 683
            }
          ]
        },
        "width": 1024,
        "height": 683,
        "caption": ""
      },
      {
        "src": "/references/wedding/DSC03516_480.jpg",
        "lightBox": {
          "src": "/references/wedding/DSC03516_1024.jpg",
          "srcSet": [
            {
              "src": "/references/wedding/DSC03516_480.jpg",
              "width": 361,
              "height": 480
            },
            {
              "src": "/references/wedding/DSC03516_600.jpg",
              "width": 452,
              "height": 600
            },
            {
              "src": "/references/wedding/DSC03516_800.jpg",
              "width": 602,
              "height": 800
            },
            {
              "src": "/references/wedding/DSC03516_1024.jpg",
              "width": 771,
              "height": 1024
            }
          ]
        },
        "width": 771,
        "height": 1024,
        "caption": ""
      },
      {
        "src": "/references/wedding/DSC03528_480.jpg",
        "lightBox": {
          "src": "/references/wedding/DSC03528_1024.jpg",
          "srcSet": [
            {
              "src": "/references/wedding/DSC03528_480.jpg",
              "width": 320,
              "height": 480
            },
            {
              "src": "/references/wedding/DSC03528_600.jpg",
              "width": 400,
              "height": 600
            },
            {
              "src": "/references/wedding/DSC03528_800.jpg",
              "width": 533,
              "height": 800
            },
            {
              "src": "/references/wedding/DSC03528_1024.jpg",
              "width": 683,
              "height": 1024
            }
          ]
        },
        "width": 683,
        "height": 1024,
        "caption": ""
      },
      {
        "src": "/references/wedding/DSC03556_480.jpg",
        "lightBox": {
          "src": "/references/wedding/DSC03556_1024.jpg",
          "srcSet": [
            {
              "src": "/references/wedding/DSC03556_480.jpg",
              "width": 480,
              "height": 320
            },
            {
              "src": "/references/wedding/DSC03556_600.jpg",
              "width": 600,
              "height": 400
            },
            {
              "src": "/references/wedding/DSC03556_800.jpg",
              "width": 800,
              "height": 533
            },
            {
              "src": "/references/wedding/DSC03556_1024.jpg",
              "width": 1024,
              "height": 683
            }
          ]
        },
        "width": 1024,
        "height": 683,
        "caption": ""
      },
      {
        "src": "/references/wedding/DSC03558_480.jpg",
        "lightBox": {
          "src": "/references/wedding/DSC03558_1024.jpg",
          "srcSet": [
            {
              "src": "/references/wedding/DSC03558_480.jpg",
              "width": 480,
              "height": 320
            },
            {
              "src": "/references/wedding/DSC03558_600.jpg",
              "width": 600,
              "height": 400
            },
            {
              "src": "/references/wedding/DSC03558_800.jpg",
              "width": 800,
              "height": 533
            },
            {
              "src": "/references/wedding/DSC03558_1024.jpg",
              "width": 1024,
              "height": 683
            }
          ]
        },
        "width": 1024,
        "height": 683,
        "caption": ""
      },
      {
        "src": "/references/wedding/DSC03598_480.jpg",
        "lightBox": {
          "src": "/references/wedding/DSC03598_1024.jpg",
          "srcSet": [
            {
              "src": "/references/wedding/DSC03598_480.jpg",
              "width": 327,
              "height": 480
            },
            {
              "src": "/references/wedding/DSC03598_600.jpg",
              "width": 408,
              "height": 600
            },
            {
              "src": "/references/wedding/DSC03598_800.jpg",
              "width": 545,
              "height": 800
            },
            {
              "src": "/references/wedding/DSC03598_1024.jpg",
              "width": 697,
              "height": 1024
            }
          ]
        },
        "width": 697,
        "height": 1024,
        "caption": ""
      },
      {
        "src": "/references/wedding/DSC03603_480.jpg",
        "lightBox": {
          "src": "/references/wedding/DSC03603_1024.jpg",
          "srcSet": [
            {
              "src": "/references/wedding/DSC03603_480.jpg",
              "width": 480,
              "height": 323
            },
            {
              "src": "/references/wedding/DSC03603_600.jpg",
              "width": 600,
              "height": 404
            },
            {
              "src": "/references/wedding/DSC03603_800.jpg",
              "width": 800,
              "height": 539
            },
            {
              "src": "/references/wedding/DSC03603_1024.jpg",
              "width": 1024,
              "height": 690
            }
          ]
        },
        "width": 1024,
        "height": 690,
        "caption": ""
      },
      {
        "src": "/references/wedding/DSC03659_480.jpg",
        "lightBox": {
          "src": "/references/wedding/DSC03659_1024.jpg",
          "srcSet": [
            {
              "src": "/references/wedding/DSC03659_480.jpg",
              "width": 320,
              "height": 480
            },
            {
              "src": "/references/wedding/DSC03659_600.jpg",
              "width": 400,
              "height": 600
            },
            {
              "src": "/references/wedding/DSC03659_800.jpg",
              "width": 533,
              "height": 800
            },
            {
              "src": "/references/wedding/DSC03659_1024.jpg",
              "width": 683,
              "height": 1024
            }
          ]
        },
        "width": 683,
        "height": 1024,
        "caption": ""
      },
      {
        "src": "/references/wedding/DSC03670_480.jpg",
        "lightBox": {
          "src": "/references/wedding/DSC03670_1024.jpg",
          "srcSet": [
            {
              "src": "/references/wedding/DSC03670_480.jpg",
              "width": 320,
              "height": 480
            },
            {
              "src": "/references/wedding/DSC03670_600.jpg",
              "width": 400,
              "height": 600
            },
            {
              "src": "/references/wedding/DSC03670_800.jpg",
              "width": 533,
              "height": 800
            },
            {
              "src": "/references/wedding/DSC03670_1024.jpg",
              "width": 683,
              "height": 1024
            }
          ]
        },
        "width": 683,
        "height": 1024,
        "caption": ""
      },
      {
        "src": "/references/wedding/DSC03687_480.jpg",
        "lightBox": {
          "src": "/references/wedding/DSC03687_1024.jpg",
          "srcSet": [
            {
              "src": "/references/wedding/DSC03687_480.jpg",
              "width": 320,
              "height": 480
            },
            {
              "src": "/references/wedding/DSC03687_600.jpg",
              "width": 400,
              "height": 600
            },
            {
              "src": "/references/wedding/DSC03687_800.jpg",
              "width": 533,
              "height": 800
            },
            {
              "src": "/references/wedding/DSC03687_1024.jpg",
              "width": 683,
              "height": 1024
            }
          ]
        },
        "width": 683,
        "height": 1024,
        "caption": ""
      },
      {
        "src": "/references/wedding/DSC03864_1_480.jpg",
        "lightBox": {
          "src": "/references/wedding/DSC03864_1_1024.jpg",
          "srcSet": [
            {
              "src": "/references/wedding/DSC03864_1_480.jpg",
              "width": 406,
              "height": 480
            },
            {
              "src": "/references/wedding/DSC03864_1_600.jpg",
              "width": 507,
              "height": 600
            },
            {
              "src": "/references/wedding/DSC03864_1_800.jpg",
              "width": 676,
              "height": 800
            },
            {
              "src": "/references/wedding/DSC03864_1_1024.jpg",
              "width": 865,
              "height": 1024
            }
          ]
        },
        "width": 865,
        "height": 1024,
        "caption": ""
      },
      {
        "src": "/references/wedding/DSC03985_480.jpg",
        "lightBox": {
          "src": "/references/wedding/DSC03985_1024.jpg",
          "srcSet": [
            {
              "src": "/references/wedding/DSC03985_480.jpg",
              "width": 480,
              "height": 320
            },
            {
              "src": "/references/wedding/DSC03985_600.jpg",
              "width": 600,
              "height": 400
            },
            {
              "src": "/references/wedding/DSC03985_800.jpg",
              "width": 800,
              "height": 533
            },
            {
              "src": "/references/wedding/DSC03985_1024.jpg",
              "width": 1024,
              "height": 683
            }
          ]
        },
        "width": 1024,
        "height": 683,
        "caption": ""
      },
      {
        "src": "/references/wedding/DSC04063_480.jpg",
        "lightBox": {
          "src": "/references/wedding/DSC04063_1024.jpg",
          "srcSet": [
            {
              "src": "/references/wedding/DSC04063_480.jpg",
              "width": 338,
              "height": 480
            },
            {
              "src": "/references/wedding/DSC04063_600.jpg",
              "width": 423,
              "height": 600
            },
            {
              "src": "/references/wedding/DSC04063_800.jpg",
              "width": 564,
              "height": 800
            },
            {
              "src": "/references/wedding/DSC04063_1024.jpg",
              "width": 722,
              "height": 1024
            }
          ]
        },
        "width": 722,
        "height": 1024,
        "caption": ""
      },
      {
        "src": "/references/wedding/DSC04250_480.jpg",
        "lightBox": {
          "src": "/references/wedding/DSC04250_1024.jpg",
          "srcSet": [
            {
              "src": "/references/wedding/DSC04250_480.jpg",
              "width": 480,
              "height": 316
            },
            {
              "src": "/references/wedding/DSC04250_600.jpg",
              "width": 600,
              "height": 395
            },
            {
              "src": "/references/wedding/DSC04250_800.jpg",
              "width": 800,
              "height": 526
            },
            {
              "src": "/references/wedding/DSC04250_1024.jpg",
              "width": 1024,
              "height": 673
            }
          ]
        },
        "width": 1024,
        "height": 673,
        "caption": ""
      },
      {
        "src": "/references/wedding/DSC04256_480.jpg",
        "lightBox": {
          "src": "/references/wedding/DSC04256_1024.jpg",
          "srcSet": [
            {
              "src": "/references/wedding/DSC04256_480.jpg",
              "width": 480,
              "height": 320
            },
            {
              "src": "/references/wedding/DSC04256_600.jpg",
              "width": 600,
              "height": 400
            },
            {
              "src": "/references/wedding/DSC04256_800.jpg",
              "width": 800,
              "height": 533
            },
            {
              "src": "/references/wedding/DSC04256_1024.jpg",
              "width": 1024,
              "height": 683
            }
          ]
        },
        "width": 1024,
        "height": 683,
        "caption": ""
      },
      {
        "src": "/references/wedding/DSC04262_480.jpg",
        "lightBox": {
          "src": "/references/wedding/DSC04262_1024.jpg",
          "srcSet": [
            {
              "src": "/references/wedding/DSC04262_480.jpg",
              "width": 480,
              "height": 320
            },
            {
              "src": "/references/wedding/DSC04262_600.jpg",
              "width": 600,
              "height": 400
            },
            {
              "src": "/references/wedding/DSC04262_800.jpg",
              "width": 800,
              "height": 533
            },
            {
              "src": "/references/wedding/DSC04262_1024.jpg",
              "width": 1024,
              "height": 683
            }
          ]
        },
        "width": 1024,
        "height": 683,
        "caption": ""
      },
      {
        "src": "/references/wedding/DSC08384_480.jpg",
        "lightBox": {
          "src": "/references/wedding/DSC08384_1024.jpg",
          "srcSet": [
            {
              "src": "/references/wedding/DSC08384_480.jpg",
              "width": 280,
              "height": 480
            },
            {
              "src": "/references/wedding/DSC08384_600.jpg",
              "width": 349,
              "height": 600
            },
            {
              "src": "/references/wedding/DSC08384_800.jpg",
              "width": 466,
              "height": 800
            },
            {
              "src": "/references/wedding/DSC08384_1024.jpg",
              "width": 596,
              "height": 1024
            }
          ]
        },
        "width": 596,
        "height": 1024,
        "caption": ""
      },
      {
        "src": "/references/wedding/DSC08388_480.jpg",
        "lightBox": {
          "src": "/references/wedding/DSC08388_1024.jpg",
          "srcSet": [
            {
              "src": "/references/wedding/DSC08388_480.jpg",
              "width": 327,
              "height": 480
            },
            {
              "src": "/references/wedding/DSC08388_600.jpg",
              "width": 408,
              "height": 600
            },
            {
              "src": "/references/wedding/DSC08388_800.jpg",
              "width": 544,
              "height": 800
            },
            {
              "src": "/references/wedding/DSC08388_1024.jpg",
              "width": 697,
              "height": 1024
            }
          ]
        },
        "width": 697,
        "height": 1024,
        "caption": ""
      },
      {
        "src": "/references/wedding/DSC08389_480.jpg",
        "lightBox": {
          "src": "/references/wedding/DSC08389_1024.jpg",
          "srcSet": [
            {
              "src": "/references/wedding/DSC08389_480.jpg",
              "width": 480,
              "height": 293
            },
            {
              "src": "/references/wedding/DSC08389_600.jpg",
              "width": 600,
              "height": 366
            },
            {
              "src": "/references/wedding/DSC08389_800.jpg",
              "width": 800,
              "height": 488
            },
            {
              "src": "/references/wedding/DSC08389_1024.jpg",
              "width": 1024,
              "height": 624
            }
          ]
        },
        "width": 1024,
        "height": 624,
        "caption": ""
      },
      {
        "src": "/references/wedding/DSC08390_480.jpg",
        "lightBox": {
          "src": "/references/wedding/DSC08390_1024.jpg",
          "srcSet": [
            {
              "src": "/references/wedding/DSC08390_480.jpg",
              "width": 480,
              "height": 270
            },
            {
              "src": "/references/wedding/DSC08390_600.jpg",
              "width": 600,
              "height": 338
            },
            {
              "src": "/references/wedding/DSC08390_800.jpg",
              "width": 800,
              "height": 450
            },
            {
              "src": "/references/wedding/DSC08390_1024.jpg",
              "width": 1024,
              "height": 576
            }
          ]
        },
        "width": 1024,
        "height": 576,
        "caption": ""
      },
      {
        "src": "/references/wedding/DSC08411_480.jpg",
        "lightBox": {
          "src": "/references/wedding/DSC08411_1024.jpg",
          "srcSet": [
            {
              "src": "/references/wedding/DSC08411_480.jpg",
              "width": 324,
              "height": 480
            },
            {
              "src": "/references/wedding/DSC08411_600.jpg",
              "width": 405,
              "height": 600
            },
            {
              "src": "/references/wedding/DSC08411_800.jpg",
              "width": 540,
              "height": 800
            },
            {
              "src": "/references/wedding/DSC08411_1024.jpg",
              "width": 692,
              "height": 1024
            }
          ]
        },
        "width": 692,
        "height": 1024,
        "caption": ""
      },
      {
        "src": "/references/wedding/DSC08413_480.jpg",
        "lightBox": {
          "src": "/references/wedding/DSC08413_1024.jpg",
          "srcSet": [
            {
              "src": "/references/wedding/DSC08413_480.jpg",
              "width": 480,
              "height": 278
            },
            {
              "src": "/references/wedding/DSC08413_600.jpg",
              "width": 600,
              "height": 347
            },
            {
              "src": "/references/wedding/DSC08413_800.jpg",
              "width": 800,
              "height": 463
            },
            {
              "src": "/references/wedding/DSC08413_1024.jpg",
              "width": 1024,
              "height": 593
            }
          ]
        },
        "width": 1024,
        "height": 593,
        "caption": ""
      },
      {
        "src": "/references/wedding/DSC08414_480.jpg",
        "lightBox": {
          "src": "/references/wedding/DSC08414_1024.jpg",
          "srcSet": [
            {
              "src": "/references/wedding/DSC08414_480.jpg",
              "width": 270,
              "height": 480
            },
            {
              "src": "/references/wedding/DSC08414_600.jpg",
              "width": 338,
              "height": 600
            },
            {
              "src": "/references/wedding/DSC08414_800.jpg",
              "width": 450,
              "height": 800
            },
            {
              "src": "/references/wedding/DSC08414_1024.jpg",
              "width": 576,
              "height": 1024
            }
          ]
        },
        "width": 576,
        "height": 1024,
        "caption": ""
      },
      {
        "src": "/references/wedding/DSC08425_480.jpg",
        "lightBox": {
          "src": "/references/wedding/DSC08425_1024.jpg",
          "srcSet": [
            {
              "src": "/references/wedding/DSC08425_480.jpg",
              "width": 270,
              "height": 480
            },
            {
              "src": "/references/wedding/DSC08425_600.jpg",
              "width": 338,
              "height": 600
            },
            {
              "src": "/references/wedding/DSC08425_800.jpg",
              "width": 450,
              "height": 800
            },
            {
              "src": "/references/wedding/DSC08425_1024.jpg",
              "width": 576,
              "height": 1024
            }
          ]
        },
        "width": 576,
        "height": 1024,
        "caption": ""
      },
      {
        "src": "/references/wedding/DSC08468_1_480.jpg",
        "lightBox": {
          "src": "/references/wedding/DSC08468_1_1024.jpg",
          "srcSet": [
            {
              "src": "/references/wedding/DSC08468_1_480.jpg",
              "width": 480,
              "height": 280
            },
            {
              "src": "/references/wedding/DSC08468_1_600.jpg",
              "width": 600,
              "height": 350
            },
            {
              "src": "/references/wedding/DSC08468_1_800.jpg",
              "width": 800,
              "height": 467
            },
            {
              "src": "/references/wedding/DSC08468_1_1024.jpg",
              "width": 1024,
              "height": 598
            }
          ]
        },
        "width": 1024,
        "height": 598,
        "caption": ""
      },
      {
        "src": "/references/wedding/DSC08474_480.jpg",
        "lightBox": {
          "src": "/references/wedding/DSC08474_1024.jpg",
          "srcSet": [
            {
              "src": "/references/wedding/DSC08474_480.jpg",
              "width": 480,
              "height": 285
            },
            {
              "src": "/references/wedding/DSC08474_600.jpg",
              "width": 600,
              "height": 356
            },
            {
              "src": "/references/wedding/DSC08474_800.jpg",
              "width": 800,
              "height": 474
            },
            {
              "src": "/references/wedding/DSC08474_1024.jpg",
              "width": 1024,
              "height": 607
            }
          ]
        },
        "width": 1024,
        "height": 607,
        "caption": ""
      },
      {
        "src": "/references/wedding/DSC08498_480.jpg",
        "lightBox": {
          "src": "/references/wedding/DSC08498_1024.jpg",
          "srcSet": [
            {
              "src": "/references/wedding/DSC08498_480.jpg",
              "width": 480,
              "height": 293
            },
            {
              "src": "/references/wedding/DSC08498_600.jpg",
              "width": 600,
              "height": 366
            },
            {
              "src": "/references/wedding/DSC08498_800.jpg",
              "width": 800,
              "height": 488
            },
            {
              "src": "/references/wedding/DSC08498_1024.jpg",
              "width": 1024,
              "height": 625
            }
          ]
        },
        "width": 1024,
        "height": 625,
        "caption": ""
      },
      {
        "src": "/references/wedding/DSC08571_480.jpg",
        "lightBox": {
          "src": "/references/wedding/DSC08571_1024.jpg",
          "srcSet": [
            {
              "src": "/references/wedding/DSC08571_480.jpg",
              "width": 480,
              "height": 270
            },
            {
              "src": "/references/wedding/DSC08571_600.jpg",
              "width": 600,
              "height": 338
            },
            {
              "src": "/references/wedding/DSC08571_800.jpg",
              "width": 800,
              "height": 450
            },
            {
              "src": "/references/wedding/DSC08571_1024.jpg",
              "width": 1024,
              "height": 576
            }
          ]
        },
        "width": 1024,
        "height": 576,
        "caption": ""
      },
      {
        "src": "/references/wedding/DSC08803_480.jpg",
        "lightBox": {
          "src": "/references/wedding/DSC08803_1024.jpg",
          "srcSet": [
            {
              "src": "/references/wedding/DSC08803_480.jpg",
              "width": 480,
              "height": 277
            },
            {
              "src": "/references/wedding/DSC08803_600.jpg",
              "width": 600,
              "height": 346
            },
            {
              "src": "/references/wedding/DSC08803_800.jpg",
              "width": 800,
              "height": 462
            },
            {
              "src": "/references/wedding/DSC08803_1024.jpg",
              "width": 1024,
              "height": 591
            }
          ]
        },
        "width": 1024,
        "height": 591,
        "caption": ""
      },
      {
        "src": "/references/wedding/DSC08829_480.jpg",
        "lightBox": {
          "src": "/references/wedding/DSC08829_1024.jpg",
          "srcSet": [
            {
              "src": "/references/wedding/DSC08829_480.jpg",
              "width": 480,
              "height": 331
            },
            {
              "src": "/references/wedding/DSC08829_600.jpg",
              "width": 600,
              "height": 414
            },
            {
              "src": "/references/wedding/DSC08829_800.jpg",
              "width": 800,
              "height": 551
            },
            {
              "src": "/references/wedding/DSC08829_1024.jpg",
              "width": 1024,
              "height": 706
            }
          ]
        },
        "width": 1024,
        "height": 706,
        "caption": ""
      },
      {
        "src": "/references/wedding/DSC08878_480.jpg",
        "lightBox": {
          "src": "/references/wedding/DSC08878_1024.jpg",
          "srcSet": [
            {
              "src": "/references/wedding/DSC08878_480.jpg",
              "width": 318,
              "height": 480
            },
            {
              "src": "/references/wedding/DSC08878_600.jpg",
              "width": 397,
              "height": 600
            },
            {
              "src": "/references/wedding/DSC08878_800.jpg",
              "width": 529,
              "height": 800
            },
            {
              "src": "/references/wedding/DSC08878_1024.jpg",
              "width": 677,
              "height": 1024
            }
          ]
        },
        "width": 677,
        "height": 1024,
        "caption": ""
      },
      {
        "src": "/references/wedding/DSC08894_480.jpg",
        "lightBox": {
          "src": "/references/wedding/DSC08894_1024.jpg",
          "srcSet": [
            {
              "src": "/references/wedding/DSC08894_480.jpg",
              "width": 332,
              "height": 480
            },
            {
              "src": "/references/wedding/DSC08894_600.jpg",
              "width": 415,
              "height": 600
            },
            {
              "src": "/references/wedding/DSC08894_800.jpg",
              "width": 553,
              "height": 800
            },
            {
              "src": "/references/wedding/DSC08894_1024.jpg",
              "width": 708,
              "height": 1024
            }
          ]
        },
        "width": 708,
        "height": 1024,
        "caption": ""
      },
      {
        "src": "/references/wedding/DSC08895_480.jpg",
        "lightBox": {
          "src": "/references/wedding/DSC08895_1024.jpg",
          "srcSet": [
            {
              "src": "/references/wedding/DSC08895_480.jpg",
              "width": 480,
              "height": 266
            },
            {
              "src": "/references/wedding/DSC08895_600.jpg",
              "width": 600,
              "height": 333
            },
            {
              "src": "/references/wedding/DSC08895_800.jpg",
              "width": 800,
              "height": 443
            },
            {
              "src": "/references/wedding/DSC08895_1024.jpg",
              "width": 1024,
              "height": 568
            }
          ]
        },
        "width": 1024,
        "height": 568,
        "caption": ""
      },
      {
        "src": "/references/wedding/DSC08908_480.jpg",
        "lightBox": {
          "src": "/references/wedding/DSC08908_1024.jpg",
          "srcSet": [
            {
              "src": "/references/wedding/DSC08908_480.jpg",
              "width": 480,
              "height": 270
            },
            {
              "src": "/references/wedding/DSC08908_600.jpg",
              "width": 600,
              "height": 338
            },
            {
              "src": "/references/wedding/DSC08908_800.jpg",
              "width": 800,
              "height": 450
            },
            {
              "src": "/references/wedding/DSC08908_1024.jpg",
              "width": 1024,
              "height": 576
            }
          ]
        },
        "width": 1024,
        "height": 576,
        "caption": ""
      },
      {
        "src": "/references/wedding/DSC08911_480.jpg",
        "lightBox": {
          "src": "/references/wedding/DSC08911_1024.jpg",
          "srcSet": [
            {
              "src": "/references/wedding/DSC08911_480.jpg",
              "width": 480,
              "height": 275
            },
            {
              "src": "/references/wedding/DSC08911_600.jpg",
              "width": 600,
              "height": 344
            },
            {
              "src": "/references/wedding/DSC08911_800.jpg",
              "width": 800,
              "height": 459
            },
            {
              "src": "/references/wedding/DSC08911_1024.jpg",
              "width": 1024,
              "height": 587
            }
          ]
        },
        "width": 1024,
        "height": 587,
        "caption": ""
      },
      {
        "src": "/references/wedding/DSC08932_480.jpg",
        "lightBox": {
          "src": "/references/wedding/DSC08932_1024.jpg",
          "srcSet": [
            {
              "src": "/references/wedding/DSC08932_480.jpg",
              "width": 286,
              "height": 480
            },
            {
              "src": "/references/wedding/DSC08932_600.jpg",
              "width": 357,
              "height": 600
            },
            {
              "src": "/references/wedding/DSC08932_800.jpg",
              "width": 476,
              "height": 800
            },
            {
              "src": "/references/wedding/DSC08932_1024.jpg",
              "width": 609,
              "height": 1024
            }
          ]
        },
        "width": 609,
        "height": 1024,
        "caption": ""
      },
      {
        "src": "/references/wedding/DSC08937_480.jpg",
        "lightBox": {
          "src": "/references/wedding/DSC08937_1024.jpg",
          "srcSet": [
            {
              "src": "/references/wedding/DSC08937_480.jpg",
              "width": 270,
              "height": 480
            },
            {
              "src": "/references/wedding/DSC08937_600.jpg",
              "width": 338,
              "height": 600
            },
            {
              "src": "/references/wedding/DSC08937_800.jpg",
              "width": 450,
              "height": 800
            },
            {
              "src": "/references/wedding/DSC08937_1024.jpg",
              "width": 576,
              "height": 1024
            }
          ]
        },
        "width": 576,
        "height": 1024,
        "caption": ""
      },
      {
        "src": "/references/wedding/DSC09048_480.jpg",
        "lightBox": {
          "src": "/references/wedding/DSC09048_1024.jpg",
          "srcSet": [
            {
              "src": "/references/wedding/DSC09048_480.jpg",
              "width": 326,
              "height": 480
            },
            {
              "src": "/references/wedding/DSC09048_600.jpg",
              "width": 407,
              "height": 600
            },
            {
              "src": "/references/wedding/DSC09048_800.jpg",
              "width": 543,
              "height": 800
            },
            {
              "src": "/references/wedding/DSC09048_1024.jpg",
              "width": 695,
              "height": 1024
            }
          ]
        },
        "width": 695,
        "height": 1024,
        "caption": ""
      },
      {
        "src": "/references/wedding/DSC09074_480.jpg",
        "lightBox": {
          "src": "/references/wedding/DSC09074_1024.jpg",
          "srcSet": [
            {
              "src": "/references/wedding/DSC09074_480.jpg",
              "width": 291,
              "height": 480
            },
            {
              "src": "/references/wedding/DSC09074_600.jpg",
              "width": 364,
              "height": 600
            },
            {
              "src": "/references/wedding/DSC09074_800.jpg",
              "width": 486,
              "height": 800
            },
            {
              "src": "/references/wedding/DSC09074_1024.jpg",
              "width": 622,
              "height": 1024
            }
          ]
        },
        "width": 622,
        "height": 1024,
        "caption": ""
      },
      {
        "src": "/references/wedding/DSC09078_480.jpg",
        "lightBox": {
          "src": "/references/wedding/DSC09078_1024.jpg",
          "srcSet": [
            {
              "src": "/references/wedding/DSC09078_480.jpg",
              "width": 480,
              "height": 250
            },
            {
              "src": "/references/wedding/DSC09078_600.jpg",
              "width": 600,
              "height": 313
            },
            {
              "src": "/references/wedding/DSC09078_800.jpg",
              "width": 800,
              "height": 417
            },
            {
              "src": "/references/wedding/DSC09078_1024.jpg",
              "width": 1024,
              "height": 534
            }
          ]
        },
        "width": 1024,
        "height": 534,
        "caption": ""
      },
      {
        "src": "/references/wedding/DSC09138_480.jpg",
        "lightBox": {
          "src": "/references/wedding/DSC09138_1024.jpg",
          "srcSet": [
            {
              "src": "/references/wedding/DSC09138_480.jpg",
              "width": 270,
              "height": 480
            },
            {
              "src": "/references/wedding/DSC09138_600.jpg",
              "width": 338,
              "height": 600
            },
            {
              "src": "/references/wedding/DSC09138_800.jpg",
              "width": 450,
              "height": 800
            },
            {
              "src": "/references/wedding/DSC09138_1024.jpg",
              "width": 576,
              "height": 1024
            }
          ]
        },
        "width": 576,
        "height": 1024,
        "caption": ""
      },
      {
        "src": "/references/wedding/DSC09242_480.jpg",
        "lightBox": {
          "src": "/references/wedding/DSC09242_1024.jpg",
          "srcSet": [
            {
              "src": "/references/wedding/DSC09242_480.jpg",
              "width": 480,
              "height": 270
            },
            {
              "src": "/references/wedding/DSC09242_600.jpg",
              "width": 600,
              "height": 338
            },
            {
              "src": "/references/wedding/DSC09242_800.jpg",
              "width": 800,
              "height": 450
            },
            {
              "src": "/references/wedding/DSC09242_1024.jpg",
              "width": 1024,
              "height": 576
            }
          ]
        },
        "width": 1024,
        "height": 576,
        "caption": ""
      },
      {
        "src": "/references/wedding/DSC09265_480.jpg",
        "lightBox": {
          "src": "/references/wedding/DSC09265_1024.jpg",
          "srcSet": [
            {
              "src": "/references/wedding/DSC09265_480.jpg",
              "width": 480,
              "height": 294
            },
            {
              "src": "/references/wedding/DSC09265_600.jpg",
              "width": 600,
              "height": 367
            },
            {
              "src": "/references/wedding/DSC09265_800.jpg",
              "width": 800,
              "height": 490
            },
            {
              "src": "/references/wedding/DSC09265_1024.jpg",
              "width": 1024,
              "height": 627
            }
          ]
        },
        "width": 1024,
        "height": 627,
        "caption": ""
      },
      {
        "src": "/references/wedding/DSC09717_480.jpg",
        "lightBox": {
          "src": "/references/wedding/DSC09717_1024.jpg",
          "srcSet": [
            {
              "src": "/references/wedding/DSC09717_480.jpg",
              "width": 361,
              "height": 480
            },
            {
              "src": "/references/wedding/DSC09717_600.jpg",
              "width": 452,
              "height": 600
            },
            {
              "src": "/references/wedding/DSC09717_800.jpg",
              "width": 602,
              "height": 800
            },
            {
              "src": "/references/wedding/DSC09717_1024.jpg",
              "width": 771,
              "height": 1024
            }
          ]
        },
        "width": 771,
        "height": 1024,
        "caption": ""
      },
      {
        "src": "/references/wedding/DSC09743_480.jpg",
        "lightBox": {
          "src": "/references/wedding/DSC09743_1024.jpg",
          "srcSet": [
            {
              "src": "/references/wedding/DSC09743_480.jpg",
              "width": 480,
              "height": 270
            },
            {
              "src": "/references/wedding/DSC09743_600.jpg",
              "width": 600,
              "height": 338
            },
            {
              "src": "/references/wedding/DSC09743_800.jpg",
              "width": 800,
              "height": 450
            },
            {
              "src": "/references/wedding/DSC09743_1024.jpg",
              "width": 1024,
              "height": 576
            }
          ]
        },
        "width": 1024,
        "height": 576,
        "caption": ""
      },
      {
        "src": "/references/wedding/DSC09780_480.jpg",
        "lightBox": {
          "src": "/references/wedding/DSC09780_1024.jpg",
          "srcSet": [
            {
              "src": "/references/wedding/DSC09780_480.jpg",
              "width": 342,
              "height": 480
            },
            {
              "src": "/references/wedding/DSC09780_600.jpg",
              "width": 427,
              "height": 600
            },
            {
              "src": "/references/wedding/DSC09780_800.jpg",
              "width": 569,
              "height": 800
            },
            {
              "src": "/references/wedding/DSC09780_1024.jpg",
              "width": 729,
              "height": 1024
            }
          ]
        },
        "width": 729,
        "height": 1024,
        "caption": ""
      },
      {
        "src": "/references/wedding/DSC09782_480.jpg",
        "lightBox": {
          "src": "/references/wedding/DSC09782_1024.jpg",
          "srcSet": [
            {
              "src": "/references/wedding/DSC09782_480.jpg",
              "width": 342,
              "height": 480
            },
            {
              "src": "/references/wedding/DSC09782_600.jpg",
              "width": 427,
              "height": 600
            },
            {
              "src": "/references/wedding/DSC09782_800.jpg",
              "width": 569,
              "height": 800
            },
            {
              "src": "/references/wedding/DSC09782_1024.jpg",
              "width": 729,
              "height": 1024
            }
          ]
        },
        "width": 729,
        "height": 1024,
        "caption": ""
      },
      {
        "src": "/references/wedding/DSC09816_480.jpg",
        "lightBox": {
          "src": "/references/wedding/DSC09816_1024.jpg",
          "srcSet": [
            {
              "src": "/references/wedding/DSC09816_480.jpg",
              "width": 327,
              "height": 480
            },
            {
              "src": "/references/wedding/DSC09816_600.jpg",
              "width": 409,
              "height": 600
            },
            {
              "src": "/references/wedding/DSC09816_800.jpg",
              "width": 545,
              "height": 800
            },
            {
              "src": "/references/wedding/DSC09816_1024.jpg",
              "width": 698,
              "height": 1024
            }
          ]
        },
        "width": 698,
        "height": 1024,
        "caption": ""
      },
      {
        "src": "/references/wedding/DSC09837_480.jpg",
        "lightBox": {
          "src": "/references/wedding/DSC09837_1024.jpg",
          "srcSet": [
            {
              "src": "/references/wedding/DSC09837_480.jpg",
              "width": 320,
              "height": 480
            },
            {
              "src": "/references/wedding/DSC09837_600.jpg",
              "width": 400,
              "height": 600
            },
            {
              "src": "/references/wedding/DSC09837_800.jpg",
              "width": 533,
              "height": 800
            },
            {
              "src": "/references/wedding/DSC09837_1024.jpg",
              "width": 683,
              "height": 1024
            }
          ]
        },
        "width": 683,
        "height": 1024,
        "caption": ""
      },
      {
        "src": "/references/wedding/DSC09839_480.jpg",
        "lightBox": {
          "src": "/references/wedding/DSC09839_1024.jpg",
          "srcSet": [
            {
              "src": "/references/wedding/DSC09839_480.jpg",
              "width": 480,
              "height": 320
            },
            {
              "src": "/references/wedding/DSC09839_600.jpg",
              "width": 600,
              "height": 400
            },
            {
              "src": "/references/wedding/DSC09839_800.jpg",
              "width": 800,
              "height": 533
            },
            {
              "src": "/references/wedding/DSC09839_1024.jpg",
              "width": 1024,
              "height": 683
            }
          ]
        },
        "width": 1024,
        "height": 683,
        "caption": ""
      },
      {
        "src": "/references/wedding/DSC09880_480.jpg",
        "lightBox": {
          "src": "/references/wedding/DSC09880_1024.jpg",
          "srcSet": [
            {
              "src": "/references/wedding/DSC09880_480.jpg",
              "width": 320,
              "height": 480
            },
            {
              "src": "/references/wedding/DSC09880_600.jpg",
              "width": 400,
              "height": 600
            },
            {
              "src": "/references/wedding/DSC09880_800.jpg",
              "width": 533,
              "height": 800
            },
            {
              "src": "/references/wedding/DSC09880_1024.jpg",
              "width": 683,
              "height": 1024
            }
          ]
        },
        "width": 683,
        "height": 1024,
        "caption": ""
      },
      {
        "src": "/references/wedding/DSC09889_480.jpg",
        "lightBox": {
          "src": "/references/wedding/DSC09889_1024.jpg",
          "srcSet": [
            {
              "src": "/references/wedding/DSC09889_480.jpg",
              "width": 480,
              "height": 320
            },
            {
              "src": "/references/wedding/DSC09889_600.jpg",
              "width": 600,
              "height": 400
            },
            {
              "src": "/references/wedding/DSC09889_800.jpg",
              "width": 800,
              "height": 533
            },
            {
              "src": "/references/wedding/DSC09889_1024.jpg",
              "width": 1024,
              "height": 683
            }
          ]
        },
        "width": 1024,
        "height": 683,
        "caption": ""
      },
      {
        "src": "/references/wedding/DSC09893_480.jpg",
        "lightBox": {
          "src": "/references/wedding/DSC09893_1024.jpg",
          "srcSet": [
            {
              "src": "/references/wedding/DSC09893_480.jpg",
              "width": 320,
              "height": 480
            },
            {
              "src": "/references/wedding/DSC09893_600.jpg",
              "width": 400,
              "height": 600
            },
            {
              "src": "/references/wedding/DSC09893_800.jpg",
              "width": 533,
              "height": 800
            },
            {
              "src": "/references/wedding/DSC09893_1024.jpg",
              "width": 683,
              "height": 1024
            }
          ]
        },
        "width": 683,
        "height": 1024,
        "caption": ""
      },
      {
        "src": "/references/wedding/I22A0846_1_480.jpg",
        "lightBox": {
          "src": "/references/wedding/I22A0846_1_1024.jpg",
          "srcSet": [
            {
              "src": "/references/wedding/I22A0846_1_480.jpg",
              "width": 480,
              "height": 320
            },
            {
              "src": "/references/wedding/I22A0846_1_600.jpg",
              "width": 600,
              "height": 400
            },
            {
              "src": "/references/wedding/I22A0846_1_800.jpg",
              "width": 800,
              "height": 533
            },
            {
              "src": "/references/wedding/I22A0846_1_1024.jpg",
              "width": 1024,
              "height": 683
            }
          ]
        },
        "width": 1024,
        "height": 683,
        "caption": ""
      },
      {
        "src": "/references/wedding/I22A0923_480.jpg",
        "lightBox": {
          "src": "/references/wedding/I22A0923_1024.jpg",
          "srcSet": [
            {
              "src": "/references/wedding/I22A0923_480.jpg",
              "width": 320,
              "height": 480
            },
            {
              "src": "/references/wedding/I22A0923_600.jpg",
              "width": 400,
              "height": 600
            },
            {
              "src": "/references/wedding/I22A0923_800.jpg",
              "width": 533,
              "height": 800
            },
            {
              "src": "/references/wedding/I22A0923_1024.jpg",
              "width": 683,
              "height": 1024
            }
          ]
        },
        "width": 683,
        "height": 1024,
        "caption": ""
      },
      {
        "src": "/references/wedding/I22A0928_480.jpg",
        "lightBox": {
          "src": "/references/wedding/I22A0928_1024.jpg",
          "srcSet": [
            {
              "src": "/references/wedding/I22A0928_480.jpg",
              "width": 320,
              "height": 480
            },
            {
              "src": "/references/wedding/I22A0928_600.jpg",
              "width": 399,
              "height": 600
            },
            {
              "src": "/references/wedding/I22A0928_800.jpg",
              "width": 533,
              "height": 800
            },
            {
              "src": "/references/wedding/I22A0928_1024.jpg",
              "width": 682,
              "height": 1024
            }
          ]
        },
        "width": 682,
        "height": 1024,
        "caption": ""
      },
      {
        "src": "/references/wedding/I22A0978_480.jpg",
        "lightBox": {
          "src": "/references/wedding/I22A0978_1024.jpg",
          "srcSet": [
            {
              "src": "/references/wedding/I22A0978_480.jpg",
              "width": 320,
              "height": 480
            },
            {
              "src": "/references/wedding/I22A0978_600.jpg",
              "width": 400,
              "height": 600
            },
            {
              "src": "/references/wedding/I22A0978_800.jpg",
              "width": 533,
              "height": 800
            },
            {
              "src": "/references/wedding/I22A0978_1024.jpg",
              "width": 683,
              "height": 1024
            }
          ]
        },
        "width": 683,
        "height": 1024,
        "caption": ""
      },
      {
        "src": "/references/wedding/I22A1124_1_480.jpg",
        "lightBox": {
          "src": "/references/wedding/I22A1124_1_1024.jpg",
          "srcSet": [
            {
              "src": "/references/wedding/I22A1124_1_480.jpg",
              "width": 320,
              "height": 480
            },
            {
              "src": "/references/wedding/I22A1124_1_600.jpg",
              "width": 400,
              "height": 600
            },
            {
              "src": "/references/wedding/I22A1124_1_800.jpg",
              "width": 533,
              "height": 800
            },
            {
              "src": "/references/wedding/I22A1124_1_1024.jpg",
              "width": 683,
              "height": 1024
            }
          ]
        },
        "width": 683,
        "height": 1024,
        "caption": ""
      },
      {
        "src": "/references/wedding/I22A1189_480.jpg",
        "lightBox": {
          "src": "/references/wedding/I22A1189_1024.jpg",
          "srcSet": [
            {
              "src": "/references/wedding/I22A1189_480.jpg",
              "width": 320,
              "height": 480
            },
            {
              "src": "/references/wedding/I22A1189_600.jpg",
              "width": 400,
              "height": 600
            },
            {
              "src": "/references/wedding/I22A1189_800.jpg",
              "width": 533,
              "height": 800
            },
            {
              "src": "/references/wedding/I22A1189_1024.jpg",
              "width": 683,
              "height": 1024
            }
          ]
        },
        "width": 683,
        "height": 1024,
        "caption": ""
      },
      {
        "src": "/references/wedding/I22A1201_480.jpg",
        "lightBox": {
          "src": "/references/wedding/I22A1201_1024.jpg",
          "srcSet": [
            {
              "src": "/references/wedding/I22A1201_480.jpg",
              "width": 320,
              "height": 480
            },
            {
              "src": "/references/wedding/I22A1201_600.jpg",
              "width": 400,
              "height": 600
            },
            {
              "src": "/references/wedding/I22A1201_800.jpg",
              "width": 533,
              "height": 800
            },
            {
              "src": "/references/wedding/I22A1201_1024.jpg",
              "width": 683,
              "height": 1024
            }
          ]
        },
        "width": 683,
        "height": 1024,
        "caption": ""
      },
      {
        "src": "/references/wedding/I22A1210_480.jpg",
        "lightBox": {
          "src": "/references/wedding/I22A1210_1024.jpg",
          "srcSet": [
            {
              "src": "/references/wedding/I22A1210_480.jpg",
              "width": 325,
              "height": 480
            },
            {
              "src": "/references/wedding/I22A1210_600.jpg",
              "width": 406,
              "height": 600
            },
            {
              "src": "/references/wedding/I22A1210_800.jpg",
              "width": 541,
              "height": 800
            },
            {
              "src": "/references/wedding/I22A1210_1024.jpg",
              "width": 692,
              "height": 1024
            }
          ]
        },
        "width": 692,
        "height": 1024,
        "caption": ""
      },
      {
        "src": "/references/wedding/I22A1215_480.jpg",
        "lightBox": {
          "src": "/references/wedding/I22A1215_1024.jpg",
          "srcSet": [
            {
              "src": "/references/wedding/I22A1215_480.jpg",
              "width": 320,
              "height": 480
            },
            {
              "src": "/references/wedding/I22A1215_600.jpg",
              "width": 400,
              "height": 600
            },
            {
              "src": "/references/wedding/I22A1215_800.jpg",
              "width": 533,
              "height": 800
            },
            {
              "src": "/references/wedding/I22A1215_1024.jpg",
              "width": 683,
              "height": 1024
            }
          ]
        },
        "width": 683,
        "height": 1024,
        "caption": ""
      },
      {
        "src": "/references/wedding/I22A1233_480.jpg",
        "lightBox": {
          "src": "/references/wedding/I22A1233_1024.jpg",
          "srcSet": [
            {
              "src": "/references/wedding/I22A1233_480.jpg",
              "width": 317,
              "height": 480
            },
            {
              "src": "/references/wedding/I22A1233_600.jpg",
              "width": 397,
              "height": 600
            },
            {
              "src": "/references/wedding/I22A1233_800.jpg",
              "width": 529,
              "height": 800
            },
            {
              "src": "/references/wedding/I22A1233_1024.jpg",
              "width": 677,
              "height": 1024
            }
          ]
        },
        "width": 677,
        "height": 1024,
        "caption": ""
      },
      {
        "src": "/references/wedding/I22A1240_1_480.jpg",
        "lightBox": {
          "src": "/references/wedding/I22A1240_1_1024.jpg",
          "srcSet": [
            {
              "src": "/references/wedding/I22A1240_1_480.jpg",
              "width": 334,
              "height": 480
            },
            {
              "src": "/references/wedding/I22A1240_1_600.jpg",
              "width": 417,
              "height": 600
            },
            {
              "src": "/references/wedding/I22A1240_1_800.jpg",
              "width": 557,
              "height": 800
            },
            {
              "src": "/references/wedding/I22A1240_1_1024.jpg",
              "width": 712,
              "height": 1024
            }
          ]
        },
        "width": 712,
        "height": 1024,
        "caption": ""
      },
      {
        "src": "/references/wedding/I22A1243_480.jpg",
        "lightBox": {
          "src": "/references/wedding/I22A1243_1024.jpg",
          "srcSet": [
            {
              "src": "/references/wedding/I22A1243_480.jpg",
              "width": 330,
              "height": 480
            },
            {
              "src": "/references/wedding/I22A1243_600.jpg",
              "width": 413,
              "height": 600
            },
            {
              "src": "/references/wedding/I22A1243_800.jpg",
              "width": 550,
              "height": 800
            },
            {
              "src": "/references/wedding/I22A1243_1024.jpg",
              "width": 705,
              "height": 1024
            }
          ]
        },
        "width": 705,
        "height": 1024,
        "caption": ""
      },
      {
        "src": "/references/wedding/I22A1318_480.jpg",
        "lightBox": {
          "src": "/references/wedding/I22A1318_1024.jpg",
          "srcSet": [
            {
              "src": "/references/wedding/I22A1318_480.jpg",
              "width": 480,
              "height": 320
            },
            {
              "src": "/references/wedding/I22A1318_600.jpg",
              "width": 600,
              "height": 400
            },
            {
              "src": "/references/wedding/I22A1318_800.jpg",
              "width": 800,
              "height": 533
            },
            {
              "src": "/references/wedding/I22A1318_1024.jpg",
              "width": 1024,
              "height": 683
            }
          ]
        },
        "width": 1024,
        "height": 683,
        "caption": ""
      },
      {
        "src": "/references/wedding/I22A1329_480.jpg",
        "lightBox": {
          "src": "/references/wedding/I22A1329_1024.jpg",
          "srcSet": [
            {
              "src": "/references/wedding/I22A1329_480.jpg",
              "width": 480,
              "height": 320
            },
            {
              "src": "/references/wedding/I22A1329_600.jpg",
              "width": 600,
              "height": 400
            },
            {
              "src": "/references/wedding/I22A1329_800.jpg",
              "width": 800,
              "height": 533
            },
            {
              "src": "/references/wedding/I22A1329_1024.jpg",
              "width": 1024,
              "height": 683
            }
          ]
        },
        "width": 1024,
        "height": 683,
        "caption": ""
      },
      {
        "src": "/references/wedding/I22A1406_480.jpg",
        "lightBox": {
          "src": "/references/wedding/I22A1406_1024.jpg",
          "srcSet": [
            {
              "src": "/references/wedding/I22A1406_480.jpg",
              "width": 480,
              "height": 320
            },
            {
              "src": "/references/wedding/I22A1406_600.jpg",
              "width": 600,
              "height": 400
            },
            {
              "src": "/references/wedding/I22A1406_800.jpg",
              "width": 800,
              "height": 533
            },
            {
              "src": "/references/wedding/I22A1406_1024.jpg",
              "width": 1024,
              "height": 683
            }
          ]
        },
        "width": 1024,
        "height": 683,
        "caption": ""
      },
      {
        "src": "/references/wedding/I22A1427_480.jpg",
        "lightBox": {
          "src": "/references/wedding/I22A1427_1024.jpg",
          "srcSet": [
            {
              "src": "/references/wedding/I22A1427_480.jpg",
              "width": 480,
              "height": 320
            },
            {
              "src": "/references/wedding/I22A1427_600.jpg",
              "width": 600,
              "height": 400
            },
            {
              "src": "/references/wedding/I22A1427_800.jpg",
              "width": 800,
              "height": 533
            },
            {
              "src": "/references/wedding/I22A1427_1024.jpg",
              "width": 1024,
              "height": 683
            }
          ]
        },
        "width": 1024,
        "height": 683,
        "caption": ""
      },
      {
        "src": "/references/wedding/I22A1464_480.jpg",
        "lightBox": {
          "src": "/references/wedding/I22A1464_1024.jpg",
          "srcSet": [
            {
              "src": "/references/wedding/I22A1464_480.jpg",
              "width": 480,
              "height": 289
            },
            {
              "src": "/references/wedding/I22A1464_600.jpg",
              "width": 600,
              "height": 361
            },
            {
              "src": "/references/wedding/I22A1464_800.jpg",
              "width": 800,
              "height": 481
            },
            {
              "src": "/references/wedding/I22A1464_1024.jpg",
              "width": 1024,
              "height": 616
            }
          ]
        },
        "width": 1024,
        "height": 616,
        "caption": ""
      },
      {
        "src": "/references/wedding/I22A1467_480.jpg",
        "lightBox": {
          "src": "/references/wedding/I22A1467_1024.jpg",
          "srcSet": [
            {
              "src": "/references/wedding/I22A1467_480.jpg",
              "width": 480,
              "height": 320
            },
            {
              "src": "/references/wedding/I22A1467_600.jpg",
              "width": 600,
              "height": 400
            },
            {
              "src": "/references/wedding/I22A1467_800.jpg",
              "width": 800,
              "height": 533
            },
            {
              "src": "/references/wedding/I22A1467_1024.jpg",
              "width": 1024,
              "height": 683
            }
          ]
        },
        "width": 1024,
        "height": 683,
        "caption": ""
      },
      {
        "src": "/references/wedding/I22A1546_480.jpg",
        "lightBox": {
          "src": "/references/wedding/I22A1546_1024.jpg",
          "srcSet": [
            {
              "src": "/references/wedding/I22A1546_480.jpg",
              "width": 480,
              "height": 319
            },
            {
              "src": "/references/wedding/I22A1546_600.jpg",
              "width": 600,
              "height": 399
            },
            {
              "src": "/references/wedding/I22A1546_800.jpg",
              "width": 800,
              "height": 531
            },
            {
              "src": "/references/wedding/I22A1546_1024.jpg",
              "width": 1024,
              "height": 680
            }
          ]
        },
        "width": 1024,
        "height": 680,
        "caption": ""
      },
      {
        "src": "/references/wedding/I22A1553_480.jpg",
        "lightBox": {
          "src": "/references/wedding/I22A1553_1024.jpg",
          "srcSet": [
            {
              "src": "/references/wedding/I22A1553_480.jpg",
              "width": 480,
              "height": 379
            },
            {
              "src": "/references/wedding/I22A1553_600.jpg",
              "width": 600,
              "height": 474
            },
            {
              "src": "/references/wedding/I22A1553_800.jpg",
              "width": 800,
              "height": 632
            },
            {
              "src": "/references/wedding/I22A1553_1024.jpg",
              "width": 1024,
              "height": 809
            }
          ]
        },
        "width": 1024,
        "height": 809,
        "caption": ""
      },
      {
        "src": "/references/wedding/I22A1560_480.jpg",
        "lightBox": {
          "src": "/references/wedding/I22A1560_1024.jpg",
          "srcSet": [
            {
              "src": "/references/wedding/I22A1560_480.jpg",
              "width": 480,
              "height": 320
            },
            {
              "src": "/references/wedding/I22A1560_600.jpg",
              "width": 600,
              "height": 400
            },
            {
              "src": "/references/wedding/I22A1560_800.jpg",
              "width": 800,
              "height": 533
            },
            {
              "src": "/references/wedding/I22A1560_1024.jpg",
              "width": 1024,
              "height": 683
            }
          ]
        },
        "width": 1024,
        "height": 683,
        "caption": ""
      },
      {
        "src": "/references/wedding/I22A1565_480.jpg",
        "lightBox": {
          "src": "/references/wedding/I22A1565_1024.jpg",
          "srcSet": [
            {
              "src": "/references/wedding/I22A1565_480.jpg",
              "width": 480,
              "height": 320
            },
            {
              "src": "/references/wedding/I22A1565_600.jpg",
              "width": 600,
              "height": 400
            },
            {
              "src": "/references/wedding/I22A1565_800.jpg",
              "width": 800,
              "height": 533
            },
            {
              "src": "/references/wedding/I22A1565_1024.jpg",
              "width": 1024,
              "height": 683
            }
          ]
        },
        "width": 1024,
        "height": 683,
        "caption": ""
      },
      {
        "src": "/references/wedding/I22A1581_480.jpg",
        "lightBox": {
          "src": "/references/wedding/I22A1581_1024.jpg",
          "srcSet": [
            {
              "src": "/references/wedding/I22A1581_480.jpg",
              "width": 311,
              "height": 480
            },
            {
              "src": "/references/wedding/I22A1581_600.jpg",
              "width": 389,
              "height": 600
            },
            {
              "src": "/references/wedding/I22A1581_800.jpg",
              "width": 519,
              "height": 800
            },
            {
              "src": "/references/wedding/I22A1581_1024.jpg",
              "width": 664,
              "height": 1024
            }
          ]
        },
        "width": 664,
        "height": 1024,
        "caption": ""
      },
      {
        "src": "/references/wedding/I22A1609_480.jpg",
        "lightBox": {
          "src": "/references/wedding/I22A1609_1024.jpg",
          "srcSet": [
            {
              "src": "/references/wedding/I22A1609_480.jpg",
              "width": 480,
              "height": 320
            },
            {
              "src": "/references/wedding/I22A1609_600.jpg",
              "width": 600,
              "height": 400
            },
            {
              "src": "/references/wedding/I22A1609_800.jpg",
              "width": 800,
              "height": 533
            },
            {
              "src": "/references/wedding/I22A1609_1024.jpg",
              "width": 1024,
              "height": 683
            }
          ]
        },
        "width": 1024,
        "height": 683,
        "caption": ""
      },
      {
        "src": "/references/wedding/I22A1670_480.jpg",
        "lightBox": {
          "src": "/references/wedding/I22A1670_1024.jpg",
          "srcSet": [
            {
              "src": "/references/wedding/I22A1670_480.jpg",
              "width": 480,
              "height": 354
            },
            {
              "src": "/references/wedding/I22A1670_600.jpg",
              "width": 600,
              "height": 443
            },
            {
              "src": "/references/wedding/I22A1670_800.jpg",
              "width": 800,
              "height": 590
            },
            {
              "src": "/references/wedding/I22A1670_1024.jpg",
              "width": 1024,
              "height": 755
            }
          ]
        },
        "width": 1024,
        "height": 755,
        "caption": ""
      },
      {
        "src": "/references/wedding/I22A1843_480.jpg",
        "lightBox": {
          "src": "/references/wedding/I22A1843_1024.jpg",
          "srcSet": [
            {
              "src": "/references/wedding/I22A1843_480.jpg",
              "width": 480,
              "height": 320
            },
            {
              "src": "/references/wedding/I22A1843_600.jpg",
              "width": 600,
              "height": 400
            },
            {
              "src": "/references/wedding/I22A1843_800.jpg",
              "width": 800,
              "height": 533
            },
            {
              "src": "/references/wedding/I22A1843_1024.jpg",
              "width": 1024,
              "height": 683
            }
          ]
        },
        "width": 1024,
        "height": 683,
        "caption": ""
      },
      {
        "src": "/references/wedding/I22A1869_480.jpg",
        "lightBox": {
          "src": "/references/wedding/I22A1869_1024.jpg",
          "srcSet": [
            {
              "src": "/references/wedding/I22A1869_480.jpg",
              "width": 480,
              "height": 320
            },
            {
              "src": "/references/wedding/I22A1869_600.jpg",
              "width": 600,
              "height": 400
            },
            {
              "src": "/references/wedding/I22A1869_800.jpg",
              "width": 800,
              "height": 533
            },
            {
              "src": "/references/wedding/I22A1869_1024.jpg",
              "width": 1024,
              "height": 683
            }
          ]
        },
        "width": 1024,
        "height": 683,
        "caption": ""
      },
      {
        "src": "/references/wedding/I22A1940_480.jpg",
        "lightBox": {
          "src": "/references/wedding/I22A1940_1024.jpg",
          "srcSet": [
            {
              "src": "/references/wedding/I22A1940_480.jpg",
              "width": 320,
              "height": 480
            },
            {
              "src": "/references/wedding/I22A1940_600.jpg",
              "width": 400,
              "height": 600
            },
            {
              "src": "/references/wedding/I22A1940_800.jpg",
              "width": 533,
              "height": 800
            },
            {
              "src": "/references/wedding/I22A1940_1024.jpg",
              "width": 683,
              "height": 1024
            }
          ]
        },
        "width": 683,
        "height": 1024,
        "caption": ""
      },
      {
        "src": "/references/wedding/I22A1990_480.jpg",
        "lightBox": {
          "src": "/references/wedding/I22A1990_1024.jpg",
          "srcSet": [
            {
              "src": "/references/wedding/I22A1990_480.jpg",
              "width": 480,
              "height": 347
            },
            {
              "src": "/references/wedding/I22A1990_600.jpg",
              "width": 600,
              "height": 433
            },
            {
              "src": "/references/wedding/I22A1990_800.jpg",
              "width": 800,
              "height": 578
            },
            {
              "src": "/references/wedding/I22A1990_1024.jpg",
              "width": 1024,
              "height": 740
            }
          ]
        },
        "width": 1024,
        "height": 740,
        "caption": ""
      },
      {
        "src": "/references/wedding/I22A1991_480.jpg",
        "lightBox": {
          "src": "/references/wedding/I22A1991_1024.jpg",
          "srcSet": [
            {
              "src": "/references/wedding/I22A1991_480.jpg",
              "width": 320,
              "height": 480
            },
            {
              "src": "/references/wedding/I22A1991_600.jpg",
              "width": 400,
              "height": 600
            },
            {
              "src": "/references/wedding/I22A1991_800.jpg",
              "width": 533,
              "height": 800
            },
            {
              "src": "/references/wedding/I22A1991_1024.jpg",
              "width": 683,
              "height": 1024
            }
          ]
        },
        "width": 683,
        "height": 1024,
        "caption": ""
      },
      {
        "src": "/references/wedding/I22A2007_480.jpg",
        "lightBox": {
          "src": "/references/wedding/I22A2007_1024.jpg",
          "srcSet": [
            {
              "src": "/references/wedding/I22A2007_480.jpg",
              "width": 480,
              "height": 320
            },
            {
              "src": "/references/wedding/I22A2007_600.jpg",
              "width": 600,
              "height": 400
            },
            {
              "src": "/references/wedding/I22A2007_800.jpg",
              "width": 800,
              "height": 533
            },
            {
              "src": "/references/wedding/I22A2007_1024.jpg",
              "width": 1024,
              "height": 683
            }
          ]
        },
        "width": 1024,
        "height": 683,
        "caption": ""
      },
      {
        "src": "/references/wedding/I22A2023_480.jpg",
        "lightBox": {
          "src": "/references/wedding/I22A2023_1024.jpg",
          "srcSet": [
            {
              "src": "/references/wedding/I22A2023_480.jpg",
              "width": 480,
              "height": 320
            },
            {
              "src": "/references/wedding/I22A2023_600.jpg",
              "width": 600,
              "height": 400
            },
            {
              "src": "/references/wedding/I22A2023_800.jpg",
              "width": 800,
              "height": 533
            },
            {
              "src": "/references/wedding/I22A2023_1024.jpg",
              "width": 1024,
              "height": 683
            }
          ]
        },
        "width": 1024,
        "height": 683,
        "caption": ""
      },
      {
        "src": "/references/wedding/I22A2029_480.jpg",
        "lightBox": {
          "src": "/references/wedding/I22A2029_1024.jpg",
          "srcSet": [
            {
              "src": "/references/wedding/I22A2029_480.jpg",
              "width": 320,
              "height": 480
            },
            {
              "src": "/references/wedding/I22A2029_600.jpg",
              "width": 400,
              "height": 600
            },
            {
              "src": "/references/wedding/I22A2029_800.jpg",
              "width": 533,
              "height": 800
            },
            {
              "src": "/references/wedding/I22A2029_1024.jpg",
              "width": 683,
              "height": 1024
            }
          ]
        },
        "width": 683,
        "height": 1024,
        "caption": ""
      },
      {
        "src": "/references/wedding/I22A2036_480.jpg",
        "lightBox": {
          "src": "/references/wedding/I22A2036_1024.jpg",
          "srcSet": [
            {
              "src": "/references/wedding/I22A2036_480.jpg",
              "width": 320,
              "height": 480
            },
            {
              "src": "/references/wedding/I22A2036_600.jpg",
              "width": 400,
              "height": 600
            },
            {
              "src": "/references/wedding/I22A2036_800.jpg",
              "width": 533,
              "height": 800
            },
            {
              "src": "/references/wedding/I22A2036_1024.jpg",
              "width": 683,
              "height": 1024
            }
          ]
        },
        "width": 683,
        "height": 1024,
        "caption": ""
      },
      {
        "src": "/references/wedding/I22A2041_480.jpg",
        "lightBox": {
          "src": "/references/wedding/I22A2041_1024.jpg",
          "srcSet": [
            {
              "src": "/references/wedding/I22A2041_480.jpg",
              "width": 480,
              "height": 311
            },
            {
              "src": "/references/wedding/I22A2041_600.jpg",
              "width": 600,
              "height": 389
            },
            {
              "src": "/references/wedding/I22A2041_800.jpg",
              "width": 800,
              "height": 518
            },
            {
              "src": "/references/wedding/I22A2041_1024.jpg",
              "width": 1024,
              "height": 663
            }
          ]
        },
        "width": 1024,
        "height": 663,
        "caption": ""
      },
      {
        "src": "/references/wedding/I22A2104_480.jpg",
        "lightBox": {
          "src": "/references/wedding/I22A2104_1024.jpg",
          "srcSet": [
            {
              "src": "/references/wedding/I22A2104_480.jpg",
              "width": 320,
              "height": 480
            },
            {
              "src": "/references/wedding/I22A2104_600.jpg",
              "width": 400,
              "height": 600
            },
            {
              "src": "/references/wedding/I22A2104_800.jpg",
              "width": 533,
              "height": 800
            },
            {
              "src": "/references/wedding/I22A2104_1024.jpg",
              "width": 683,
              "height": 1024
            }
          ]
        },
        "width": 683,
        "height": 1024,
        "caption": ""
      },
      {
        "src": "/references/wedding/IMG_0003_480.jpg",
        "lightBox": {
          "src": "/references/wedding/IMG_0003_1024.jpg",
          "srcSet": [
            {
              "src": "/references/wedding/IMG_0003_480.jpg",
              "width": 480,
              "height": 320
            },
            {
              "src": "/references/wedding/IMG_0003_600.jpg",
              "width": 600,
              "height": 400
            },
            {
              "src": "/references/wedding/IMG_0003_800.jpg",
              "width": 800,
              "height": 533
            },
            {
              "src": "/references/wedding/IMG_0003_1024.jpg",
              "width": 1024,
              "height": 683
            }
          ]
        },
        "width": 1024,
        "height": 683,
        "caption": ""
      },
      {
        "src": "/references/wedding/IMG_0020_480.jpg",
        "lightBox": {
          "src": "/references/wedding/IMG_0020_1024.jpg",
          "srcSet": [
            {
              "src": "/references/wedding/IMG_0020_480.jpg",
              "width": 320,
              "height": 480
            },
            {
              "src": "/references/wedding/IMG_0020_600.jpg",
              "width": 400,
              "height": 600
            },
            {
              "src": "/references/wedding/IMG_0020_800.jpg",
              "width": 533,
              "height": 800
            },
            {
              "src": "/references/wedding/IMG_0020_1024.jpg",
              "width": 683,
              "height": 1024
            }
          ]
        },
        "width": 683,
        "height": 1024,
        "caption": ""
      },
      {
        "src": "/references/wedding/IMG_0084_480.jpg",
        "lightBox": {
          "src": "/references/wedding/IMG_0084_1024.jpg",
          "srcSet": [
            {
              "src": "/references/wedding/IMG_0084_480.jpg",
              "width": 320,
              "height": 480
            },
            {
              "src": "/references/wedding/IMG_0084_600.jpg",
              "width": 400,
              "height": 600
            },
            {
              "src": "/references/wedding/IMG_0084_800.jpg",
              "width": 533,
              "height": 800
            },
            {
              "src": "/references/wedding/IMG_0084_1024.jpg",
              "width": 683,
              "height": 1024
            }
          ]
        },
        "width": 683,
        "height": 1024,
        "caption": ""
      },
      {
        "src": "/references/wedding/IMG_0151_2_480.jpg",
        "lightBox": {
          "src": "/references/wedding/IMG_0151_2_1024.jpg",
          "srcSet": [
            {
              "src": "/references/wedding/IMG_0151_2_480.jpg",
              "width": 320,
              "height": 480
            },
            {
              "src": "/references/wedding/IMG_0151_2_600.jpg",
              "width": 400,
              "height": 600
            },
            {
              "src": "/references/wedding/IMG_0151_2_800.jpg",
              "width": 533,
              "height": 800
            },
            {
              "src": "/references/wedding/IMG_0151_2_1024.jpg",
              "width": 683,
              "height": 1024
            }
          ]
        },
        "width": 683,
        "height": 1024,
        "caption": ""
      },
      {
        "src": "/references/wedding/IMG_0164_1_480.jpg",
        "lightBox": {
          "src": "/references/wedding/IMG_0164_1_1024.jpg",
          "srcSet": [
            {
              "src": "/references/wedding/IMG_0164_1_480.jpg",
              "width": 480,
              "height": 330
            },
            {
              "src": "/references/wedding/IMG_0164_1_600.jpg",
              "width": 600,
              "height": 412
            },
            {
              "src": "/references/wedding/IMG_0164_1_800.jpg",
              "width": 800,
              "height": 549
            },
            {
              "src": "/references/wedding/IMG_0164_1_1024.jpg",
              "width": 1024,
              "height": 703
            }
          ]
        },
        "width": 1024,
        "height": 703,
        "caption": ""
      },
      {
        "src": "/references/wedding/IMG_0290_480.jpg",
        "lightBox": {
          "src": "/references/wedding/IMG_0290_1024.jpg",
          "srcSet": [
            {
              "src": "/references/wedding/IMG_0290_480.jpg",
              "width": 480,
              "height": 320
            },
            {
              "src": "/references/wedding/IMG_0290_600.jpg",
              "width": 600,
              "height": 400
            },
            {
              "src": "/references/wedding/IMG_0290_800.jpg",
              "width": 800,
              "height": 533
            },
            {
              "src": "/references/wedding/IMG_0290_1024.jpg",
              "width": 1024,
              "height": 683
            }
          ]
        },
        "width": 1024,
        "height": 683,
        "caption": ""
      },
      {
        "src": "/references/wedding/IMG_0295_480.jpg",
        "lightBox": {
          "src": "/references/wedding/IMG_0295_1024.jpg",
          "srcSet": [
            {
              "src": "/references/wedding/IMG_0295_480.jpg",
              "width": 480,
              "height": 361
            },
            {
              "src": "/references/wedding/IMG_0295_600.jpg",
              "width": 600,
              "height": 451
            },
            {
              "src": "/references/wedding/IMG_0295_800.jpg",
              "width": 800,
              "height": 601
            },
            {
              "src": "/references/wedding/IMG_0295_1024.jpg",
              "width": 1024,
              "height": 769
            }
          ]
        },
        "width": 1024,
        "height": 769,
        "caption": ""
      },
      {
        "src": "/references/wedding/IMG_0303_480.jpg",
        "lightBox": {
          "src": "/references/wedding/IMG_0303_1024.jpg",
          "srcSet": [
            {
              "src": "/references/wedding/IMG_0303_480.jpg",
              "width": 480,
              "height": 320
            },
            {
              "src": "/references/wedding/IMG_0303_600.jpg",
              "width": 600,
              "height": 400
            },
            {
              "src": "/references/wedding/IMG_0303_800.jpg",
              "width": 800,
              "height": 533
            },
            {
              "src": "/references/wedding/IMG_0303_1024.jpg",
              "width": 1024,
              "height": 683
            }
          ]
        },
        "width": 1024,
        "height": 683,
        "caption": ""
      },
      {
        "src": "/references/wedding/IMG_0304_480.jpg",
        "lightBox": {
          "src": "/references/wedding/IMG_0304_1024.jpg",
          "srcSet": [
            {
              "src": "/references/wedding/IMG_0304_480.jpg",
              "width": 480,
              "height": 320
            },
            {
              "src": "/references/wedding/IMG_0304_600.jpg",
              "width": 600,
              "height": 400
            },
            {
              "src": "/references/wedding/IMG_0304_800.jpg",
              "width": 800,
              "height": 533
            },
            {
              "src": "/references/wedding/IMG_0304_1024.jpg",
              "width": 1024,
              "height": 683
            }
          ]
        },
        "width": 1024,
        "height": 683,
        "caption": ""
      },
      {
        "src": "/references/wedding/IMG_0336_1_480.jpg",
        "lightBox": {
          "src": "/references/wedding/IMG_0336_1_1024.jpg",
          "srcSet": [
            {
              "src": "/references/wedding/IMG_0336_1_480.jpg",
              "width": 480,
              "height": 320
            },
            {
              "src": "/references/wedding/IMG_0336_1_600.jpg",
              "width": 600,
              "height": 400
            },
            {
              "src": "/references/wedding/IMG_0336_1_800.jpg",
              "width": 800,
              "height": 533
            },
            {
              "src": "/references/wedding/IMG_0336_1_1024.jpg",
              "width": 1024,
              "height": 683
            }
          ]
        },
        "width": 1024,
        "height": 683,
        "caption": ""
      },
      {
        "src": "/references/wedding/IMG_0342_480.jpg",
        "lightBox": {
          "src": "/references/wedding/IMG_0342_1024.jpg",
          "srcSet": [
            {
              "src": "/references/wedding/IMG_0342_480.jpg",
              "width": 480,
              "height": 320
            },
            {
              "src": "/references/wedding/IMG_0342_600.jpg",
              "width": 600,
              "height": 400
            },
            {
              "src": "/references/wedding/IMG_0342_800.jpg",
              "width": 800,
              "height": 533
            },
            {
              "src": "/references/wedding/IMG_0342_1024.jpg",
              "width": 1024,
              "height": 683
            }
          ]
        },
        "width": 1024,
        "height": 683,
        "caption": ""
      },
      {
        "src": "/references/wedding/IMG_0346_480.jpg",
        "lightBox": {
          "src": "/references/wedding/IMG_0346_1024.jpg",
          "srcSet": [
            {
              "src": "/references/wedding/IMG_0346_480.jpg",
              "width": 480,
              "height": 273
            },
            {
              "src": "/references/wedding/IMG_0346_600.jpg",
              "width": 600,
              "height": 341
            },
            {
              "src": "/references/wedding/IMG_0346_800.jpg",
              "width": 800,
              "height": 455
            },
            {
              "src": "/references/wedding/IMG_0346_1024.jpg",
              "width": 1024,
              "height": 582
            }
          ]
        },
        "width": 1024,
        "height": 582,
        "caption": ""
      },
      {
        "src": "/references/wedding/IMG_0364_480.jpg",
        "lightBox": {
          "src": "/references/wedding/IMG_0364_1024.jpg",
          "srcSet": [
            {
              "src": "/references/wedding/IMG_0364_480.jpg",
              "width": 320,
              "height": 480
            },
            {
              "src": "/references/wedding/IMG_0364_600.jpg",
              "width": 400,
              "height": 600
            },
            {
              "src": "/references/wedding/IMG_0364_800.jpg",
              "width": 533,
              "height": 800
            },
            {
              "src": "/references/wedding/IMG_0364_1024.jpg",
              "width": 683,
              "height": 1024
            }
          ]
        },
        "width": 683,
        "height": 1024,
        "caption": ""
      },
      {
        "src": "/references/wedding/IMG_1006_480.jpg",
        "lightBox": {
          "src": "/references/wedding/IMG_1006_1024.jpg",
          "srcSet": [
            {
              "src": "/references/wedding/IMG_1006_480.jpg",
              "width": 320,
              "height": 480
            },
            {
              "src": "/references/wedding/IMG_1006_600.jpg",
              "width": 400,
              "height": 600
            },
            {
              "src": "/references/wedding/IMG_1006_800.jpg",
              "width": 533,
              "height": 800
            },
            {
              "src": "/references/wedding/IMG_1006_1024.jpg",
              "width": 683,
              "height": 1024
            }
          ]
        },
        "width": 683,
        "height": 1024,
        "caption": ""
      },
      {
        "src": "/references/wedding/IMG_1160_480.jpg",
        "lightBox": {
          "src": "/references/wedding/IMG_1160_1024.jpg",
          "srcSet": [
            {
              "src": "/references/wedding/IMG_1160_480.jpg",
              "width": 320,
              "height": 480
            },
            {
              "src": "/references/wedding/IMG_1160_600.jpg",
              "width": 400,
              "height": 600
            },
            {
              "src": "/references/wedding/IMG_1160_800.jpg",
              "width": 533,
              "height": 800
            },
            {
              "src": "/references/wedding/IMG_1160_1024.jpg",
              "width": 683,
              "height": 1024
            }
          ]
        },
        "width": 683,
        "height": 1024,
        "caption": ""
      },
      {
        "src": "/references/wedding/IMG_1173_480.jpg",
        "lightBox": {
          "src": "/references/wedding/IMG_1173_1024.jpg",
          "srcSet": [
            {
              "src": "/references/wedding/IMG_1173_480.jpg",
              "width": 314,
              "height": 480
            },
            {
              "src": "/references/wedding/IMG_1173_600.jpg",
              "width": 393,
              "height": 600
            },
            {
              "src": "/references/wedding/IMG_1173_800.jpg",
              "width": 524,
              "height": 800
            },
            {
              "src": "/references/wedding/IMG_1173_1024.jpg",
              "width": 671,
              "height": 1024
            }
          ]
        },
        "width": 671,
        "height": 1024,
        "caption": ""
      },
      {
        "src": "/references/wedding/IMG_1183_480.jpg",
        "lightBox": {
          "src": "/references/wedding/IMG_1183_1024.jpg",
          "srcSet": [
            {
              "src": "/references/wedding/IMG_1183_480.jpg",
              "width": 480,
              "height": 320
            },
            {
              "src": "/references/wedding/IMG_1183_600.jpg",
              "width": 600,
              "height": 400
            },
            {
              "src": "/references/wedding/IMG_1183_800.jpg",
              "width": 800,
              "height": 533
            },
            {
              "src": "/references/wedding/IMG_1183_1024.jpg",
              "width": 1024,
              "height": 683
            }
          ]
        },
        "width": 1024,
        "height": 683,
        "caption": ""
      },
      {
        "src": "/references/wedding/IMG_1221_480.jpg",
        "lightBox": {
          "src": "/references/wedding/IMG_1221_1024.jpg",
          "srcSet": [
            {
              "src": "/references/wedding/IMG_1221_480.jpg",
              "width": 480,
              "height": 320
            },
            {
              "src": "/references/wedding/IMG_1221_600.jpg",
              "width": 600,
              "height": 400
            },
            {
              "src": "/references/wedding/IMG_1221_800.jpg",
              "width": 800,
              "height": 533
            },
            {
              "src": "/references/wedding/IMG_1221_1024.jpg",
              "width": 1024,
              "height": 683
            }
          ]
        },
        "width": 1024,
        "height": 683,
        "caption": ""
      },
      {
        "src": "/references/wedding/IMG_1263_480.jpg",
        "lightBox": {
          "src": "/references/wedding/IMG_1263_1024.jpg",
          "srcSet": [
            {
              "src": "/references/wedding/IMG_1263_480.jpg",
              "width": 320,
              "height": 480
            },
            {
              "src": "/references/wedding/IMG_1263_600.jpg",
              "width": 400,
              "height": 600
            },
            {
              "src": "/references/wedding/IMG_1263_800.jpg",
              "width": 533,
              "height": 800
            },
            {
              "src": "/references/wedding/IMG_1263_1024.jpg",
              "width": 683,
              "height": 1024
            }
          ]
        },
        "width": 683,
        "height": 1024,
        "caption": ""
      }
    ]
  }
}

export const getGalleryData = (id: string): ImageLibrary | undefined  => {
  if (!imageRefernceLibrary[id]) return

  return {
    images: imageRefernceLibrary[id].images.map((image: CustomImage) => ({
        ...image,
        src: useHref(image.src),
        lightBox: {
          src: useHref(image.lightBox.src),
          srcSet: image.lightBox.srcSet.map(src => ({...src, src: useHref(src.src)})),
        }
      })
    )
  }
}
