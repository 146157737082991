import * as React from 'react'
import * as ReactDOMClient from 'react-dom/client'
import CssBaseline from '@mui/material/CssBaseline'
import IndexRoute from './modules/route/IndexRoute'
import * as serviceWorker from './serviceWorker'
import { ThemeProvider } from '@mui/material/styles'
import theme from './modules/theme'
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom"
import GalleryRoute from './modules/route/GalleryRoute'
import RootRoute from './modules/route/RootRoute'
import { GlobalStyles } from '@mui/material'

const root = ReactDOMClient.createRoot(document.getElementById('root')!)

//<BrowserRouter basename="/beta"></BrowserRouter>

root.render(
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <GlobalStyles
      styles={{
        body: { backgroundColor: "#E9E9E9" },
        ul: { 
          margin: 0,
          padding: 0,
          listStyle: 'none'
        } 
      }}
    />
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<RootRoute />}>
          <Route index element={<IndexRoute />} />
          <Route path="gallery/:id" element={<GalleryRoute />} />  
          <Route path='*' element={<Navigate to="/" />} />
        </Route>
      </Routes>
    </BrowserRouter>
  </ThemeProvider>
)

serviceWorker.unregister()
