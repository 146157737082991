import * as React from 'react'
import { Gallery } from "react-grid-gallery"
import { CustomImage, ImageLibrary } from '../../data'
import Lightbox from "yet-another-react-lightbox"
import "yet-another-react-lightbox/styles.css"
import { Container } from '@mui/system'

export default function DetailGallery({library}: {library: ImageLibrary}) {
    const [lightBoxStatus, setOpen] = React.useState<{open:boolean, index?: number}>({ open:false})
    const handleClick = (index: number, item: CustomImage) => {
      setOpen({ open:true, index})
    }

    return (
        <Container sx={{ mb: 0 }} maxWidth={false} disableGutters>
          <Gallery
            margin={0}
            images={library.images}
            onClick={handleClick}
            enableImageSelection={false}
          />
          <Lightbox
            open={lightBoxStatus.open}
            index={lightBoxStatus.index}
            close={() => setOpen({ open:false})}
            slides={library.images.map((image: CustomImage) => image.lightBox )}
          />
      </Container>
    )
}