import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import Container from '@mui/material/Container';
import Typography from '../components/Typography';
import Modal from '@mui/material/Modal';
import YoutubeEmbed from '../components/YoutubeEmbed';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { useHref, useNavigate } from 'react-router-dom';

const ImageBackdrop = styled('div')(({ theme }) => ({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  background: '#000',
  opacity: 0.5,
  transition: theme.transitions.create('opacity'),
}));

const ImageIconButton = styled(ButtonBase)(({ theme }) => ({
  position: 'relative',
  display: 'block',
  padding: 0,
  borderRadius: 0,
  height: '40vh',
  [theme.breakpoints.down('md')]: {
    width: '100% !important',
    height: 100,
  },
  '&:hover': {
    zIndex: 1,
  },
  '&:hover .imageBackdrop': {
    opacity: 0.15,
  },
  '&:hover .imageMarked': {
    opacity: 0,
  },
  '&:hover .videoTitle': {
    color: `${theme.palette.secondary.dark}`,
    border: `4px solid ${theme.palette.secondary.dark}`,
    borderRadius: 40,
    paddingTop: '0.3em',
  },
  '&:hover .imageTitle': {
    border: `4px solid ${theme.palette.secondary.dark}`,
  },
  '& .videoTitle': {
    paddingTop: 'calc(0.3em + 4px)',
    width: '3em',
    height: '3em',
    position: 'relative',
    color: `${theme.palette.secondary.dark}`
  },
  '& .imageTitle': {
    position: 'relative',
    padding: `${theme.spacing(2)} ${theme.spacing(4)} 14px`,
  },
  '& .imageMarked': {
    height: 3,
    width: 18,
    background: theme.palette.secondary.main,
    position: 'absolute',
    bottom: -2,
    left: 'calc(50% - 9px)',
    transition: theme.transitions.create('opacity'),
  },
}));

const videoModalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  maxWidth: '890px',
  aspectRatio: '16 / 9',
  boxShadow: 24,
};


export default function ProductCategories({references}) {
  const [open, setOpen] = React.useState({open: false, videoId: ''});
  const handleClose = () => {
    setOpen({open: false, videoId: ''});
  };

  const referenceCopy = JSON.parse(JSON.stringify(references))
  referenceCopy.images = referenceCopy.images.map(reference => {
    if (!reference.url && reference.type === 'youtubeVideo' ) {
      reference.url = `https://i3.ytimg.com/vi/${reference.videoId}/maxresdefault.jpg`
    } else {
      reference.url = useHref(reference.url)
    }

    return reference
  })

  const TitleTypo = ({reference}) => {
    if (reference.type === 'youtubeVideo') {
      return (
        <Typography
          component="h3"
          variant="h6"
          color="inherit"
          className="videoTitle"
        >
          <PlayArrowIcon fontSize='large' />
        </Typography>
      )
    } else {
      return (
        <Typography
          component="h3"
          variant="h6"
          color="inherit"
          className="imageTitle"
        >
          {reference.title}
          <div className="imageMarked" />
        </Typography>
      )
    }
  }

  let navigate = useNavigate();

  //<!-- <DetailGallery library={imageRefernceLibrary.wedding} /> -->
  return (
    <Container sx={{ mb: 4 }} maxWidth={false} disableGutters>
      
      <Modal
        open={open.open}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
      <Box sx={videoModalStyle}>
          <YoutubeEmbed embedId={open.videoId} />
      </Box>
      </Modal>
      <Container sx={{ mb: 4 }} maxWidth={false} disableGutters>
        <Box sx={{ display: 'flex', flexWrap: 'wrap' }}  >
          {referenceCopy.images.map((reference) => (
            <ImageIconButton
              key={reference.title}
              style={{
                width: reference.width,
              }}
              onClick={() => {
                  if (reference.type === 'youtubeVideo') setOpen({open: true, videoId: reference.videoId})
                  else if (reference.type === 'photoGallery') {
                    navigate(`/gallery/${reference.galleryId}`)
                }
              }}
            >
              <Box
                sx={{
                  position: 'absolute',
                  left: 0,
                  right: 0,
                  top: 0,
                  bottom: 0,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center 40%',
                  backgroundImage: `url(${reference.url})`,
                }}
              />
              <ImageBackdrop className="imageBackdrop" />
              <Box
                sx={{
                  position: 'absolute',
                  left: 0,
                  right: 0,
                  top: 0,
                  bottom: 0,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  color: 'common.white',
                }}
              >
                <TitleTypo {...{reference}} />
              </Box>
            </ImageIconButton>
          ))}
        </Box>
      </Container>
    </Container>
  );
}
