import * as React from 'react';
import ProductCategories from '../views/ProductCategories';
import Pricing from '../views/Pricing'
import { tiers, references } from '../../data'
import WeddingFilm from '../views/WeddingFilm';

//https://github.com/joshwnj/react-visibility-sensor

export default function IndexRoute() {
  return (
    <React.Fragment>
      <ProductCategories {...{references}} />
      <Pricing {...{tiers}} />
      {/*<WeddingFilm />*/}
    </React.Fragment>
  )
}
