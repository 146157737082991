import * as React from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

const LineRoot = styled(Box)(({ theme, size }) => ({
  borderTop: 1,
  borderColor: theme.palette.secondary.main,
  borderStyle: 'solid',
  margin: 0,
  padding: 0,
  height :0,
}))

function Line(props) {
  return <LineRoot {...props}/>
}

export default Line;
