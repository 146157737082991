import * as React from 'react';
import { Outlet, useHref } from 'react-router-dom';
import AppHeader from '../views/AppHeader';
import { references } from '../../data';
import Line from '../components/Line';
import AppFooter from '../views/AppFooter';

export default function RootRoute() {
  return (
    <React.Fragment>
      <AppHeader backgroundImage={useHref(references.coverImage)} />
      <Line/>
      <Outlet />
      <AppFooter />
    </React.Fragment>
  )
}
