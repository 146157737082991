
export const VelcordMediaLogoName = ({ color = "#C79B75" }) => (
	<svg
        fill={color}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1176.17 31.54"
        width="330px"
        height="60px"
	>
        <g>
			<path d="M29.4 112.3H24.4H23.6C17.8 112.3 16.9 105.4 16.7 104.4L0.7 0H9.5C10.3 0 12.2 0.6 12.9 4.3L23.7 74.7L35.1 -0.1H46.7L29.4 112.3Z"/>
			<path d="M141.2 100.9C139.9 103.8 138 108.3 137.5 109.2C136.7 110.6 134.9 112.2 131.2 112.2H105.5V3.2C105.7 1.4 107.1 0 109 0H139L135.1 8.3C134.5 9.7 132.7 11.5 129 11.5H119.7C118.1 11.5 117 12.6 117 14.2V47.6H134.3C133 50.5 131.1 55.1 130.6 56.1C129.8 57.5 128.2 59.1 124.5 59.1H119.7C118.3 59.1 117 60.4 117 61.8V100.8H141.2Z"/>
			<path d="M238.1 100.9C236.8 103.8 234.9 108.3 234.4 109.2C233.6 110.6 231.8 112.2 228.1 112.2H200.5V0H208.5C210.4 0.2 212 1.6 212 3.5V100.9H238.1Z"/>
			<path d="M321 99.1C321 106.8 314.9 113.1 307.2 113.4H300.1C292.1 113.4 285.7 107 285.7 99.1V13.1C285.7 5.2 292.1 -1.2 300.1 -1.2H307.2C314.9 -1 321 5.4 321 13.1V30.3C321 34 319.4 35.8 318 36.4L309.7 40.3V15.1C309.7 11.7 307 9 303.4 9C300 9 297.3 11.7 297.3 15.1V97.1C297.3 100.6 300 103.4 303.4 103.4C306.9 103.4 309.7 100.7 309.7 97.1V82C309.7 78.3 311.3 76.5 312.7 75.7C313.7 75.2 318.2 73.3 321 72V99.1Z"/>
			<path d="M403.9 -1.1C411.6 -0.8 417.9 5.5 417.9 13.2V99.3C417.7 107 411.6 113.1 403.9 113.4H395.1C387.2 113.4 380.8 107 380.7 99.3V13.2C380.7 5.3 387.1 -1.1 395.1 -1.1H403.9ZM400.1 103.3C403.5 103.3 406.2 100.6 406.2 97V15.2C406.2 11.7 403.5 8.9 400.1 8.9H398.3C394.9 9.1 392.2 11.8 392.2 15.2V97C392.2 100.4 394.9 103.1 398.3 103.3H400.1Z"/>
			<path d="M501.3 112.3V71.2C501.3 65.7 498.4 64 495.4 64H489.3V112.3H477.7V0H498.7C506.4 0 512.8 6.3 512.8 14.1V48.7C512.8 48.7 513.3 57.5 508.6 58.6C513.1 60.9 512.8 69.2 512.8 69.2V112.2H501.3ZM495.4 53.1C498.4 53.1 501.3 51.8 501.3 46.7V16.5C501.3 12.3 498.4 10.7 495.4 10.7H489.3V53.1H495.4Z"/>
			<path d="M595.8 0C603.7 0 609.9 6.3 610.1 14.1V98.2C609.9 106.1 603.7 112.3 595.8 112.3H573.5V0H595.8ZM598.7 17.3C598.7 13.1 595.8 11.5 592.6 11.5H585.1V100.8H592.6C595.8 100.8 598.7 99.2 598.7 94.9V17.3Z"/>
			<path d="M811.1 112.3H799.7V54.4L791 92.9H787.5C780.8 92.9 779.5 86.2 778.8 83.8L772.1 54.4V112.3H760.7V0H767.8C772.3 0 772.9 4.2 772.9 4.2L786.2 68.7L798.9 4C799.1 3.5 799.9 0 804 0H811.1V112.3Z"/>
			<path d="M906.4 100.9C905.1 103.8 903.2 108.3 902.7 109.2C901.9 110.6 900.1 112.2 896.4 112.2H870.7V3.2C870.9 1.4 872.3 0 874.2 0H904.2L900.3 8.3C899.7 9.7 897.9 11.5 894.2 11.5H884.9C883.3 11.5 882.2 12.6 882.2 14.2V47.6H899.5C898.2 50.5 896.3 55.1 895.8 56.1C895 57.5 893.4 59.1 889.7 59.1H884.9C883.5 59.1 882.2 60.4 882.2 61.8V100.8H906.4Z"/>
			<path d="M984 0C991.9 0 998.1 6.3 998.3 14.1V98.2C998.1 106.1 991.9 112.3 984 112.3H961.7V0H984ZM986.9 17.3C986.9 13.1 984 11.5 980.8 11.5H973.3V100.8H980.8C984 100.8 986.9 99.2 986.9 94.9V17.3Z"/>
			<path d="M1069.3 0C1072.7 0 1074 2.6 1074 4.5V112.3H1065.8C1063.1 112.3 1062.6 110.7 1062.4 109.9V0H1069.3Z"/>
			<path d="M1176 112.3H1163.3L1153.2 35L1143.1 112.3H1130.4L1147.4 0H1153.7C1158.5 0 1159.3 3 1159.8 6.1L1176 112.3Z"/>
		</g>
	</svg>
)
