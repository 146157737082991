import React from "react";
import PropTypes from "prop-types";
import YouTube from 'react-youtube';

//const VideoResponsive = styled(Box)(({ theme, size }) => ({
//    overflow: 'hidden',
//    paddingBottom: '56.25%',
//    position: 'relative',
//    height: 0,
//  }))
//
//  const style = {
//    left: '0',
//    top: '0',
//    height: '100%',
//    width: '100%',
//    position: 'absolute'
//  };
  const opts = {
    width: '100%',
    height: '100%',
    playerVars: {
      autoplay: 1,
    },
  };

  const playerCssStyle = { 
    width: '100%',
    height: '100%',
  }

const YoutubeEmbed = ({ embedId }) => (
   <YouTube videoId={embedId} style={playerCssStyle} opts={opts} />
)

  // old return
  //<VideoResponsive>
  //  <iframe
  //    style={{...style}}
  //    src={`https://www.youtube.com/embed/${embedId}`}
  //    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
  //    allowFullScreen
  //    title="Embedded youtube"
  //  />
  //</VideoResponsive>


YoutubeEmbed.propTypes = {
  embedId: PropTypes.string.isRequired
};

export default YoutubeEmbed;
