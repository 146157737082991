import React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { ServicesTier } from 'src/data';
import { secondaryBoldColor } from '../theme';
  
export default function PriceTabPanel ({tiers}: ServicesTier) {
    return (
      <Container maxWidth="md" component="main">
          <Grid container spacing={2} alignItems="flex-end" sx={{ mt: 1 }}>
            {tiers.map((tier) => (
              <Grid
                item
                key={tier.title}
                xs={12}
                sm={6}
                md={4}
              >
                <Card>
                  <CardHeader
                    title={tier.title}
                    subheader={tier.subheader}
                    titleTypographyProps={{ align: 'center', fontWeight: 400, }}
                    subheaderTypographyProps={{
                      align: 'center',
                    }}
                    sx={{
                      //fontWeight: '600',
                      backgroundColor: (theme) =>
                        theme.palette.mode === 'light'
                          ? theme.palette.grey[200]
                          : theme.palette.grey[700],
                    }}
                  />
                  <CardContent>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'baseline',
                        mb: 2,
                      }}
                    >
                      <Typography component="h2" variant="h4" color={secondaryBoldColor}>
                        {tier.price} Ft
                      </Typography>
                    </Box>
                    <ul>
                      {tier.description.map((line, index) => (
                        <Typography
                          component="li"
                          variant="subtitle1"
                          align="center"
                          key={`${tier.title}${index}`}
                        >
                          {line.short}
                        </Typography>
                      ))}
                    </ul>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
    )
  }
